import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';

import {
    B2B_KEYS_GENERATION_STEP_URL,
    B2B_REGISTER_STEP_URL,
    B2C_ADDITIONAL_INFO_STEP_URL,
    B2C_DOCUMENT_VERIFICATION,
    B2C_IDENTIFICATION_STEP_URL,
    B2C_KEYS_GENERATION_STEP_URL,
    B2C_REGISTER_STEP_URL,
    PROFILE_URL,
} from '@core/configs';

import { EUserRole } from '@share/enums/role.enum';
import { UserService } from '@share/services/user.service';
import { Store } from '@share/helpers/store';

export enum ESIGN_UP_B2B_STEPS {
    REGISTRATION,
    KEYS_GENERATION,
}

export enum ESIGN_UP_B2C_STEPS {
    KYC,
    REGISTRATION,
    ADD_INFO,
    DOCUMENT_VERIFICATION,
    KEYS_GENERATION,
}

interface ISignupStore {
    type: EUserRole;
    step: ESIGN_UP_B2B_STEPS | ESIGN_UP_B2C_STEPS;
    shouldCreateWallet: boolean;
}

const INITIAL_VALUES: ISignupStore = {
    type: null,
    step: 0,
    shouldCreateWallet: false,
};

const b2cUserSteps = [
    B2C_IDENTIFICATION_STEP_URL,
    B2C_REGISTER_STEP_URL,
    B2C_ADDITIONAL_INFO_STEP_URL,
    B2C_DOCUMENT_VERIFICATION,
    B2C_KEYS_GENERATION_STEP_URL,
    PROFILE_URL,
];

const b2bUserSteps = [B2B_REGISTER_STEP_URL, B2B_KEYS_GENERATION_STEP_URL, PROFILE_URL];

@Injectable({
    providedIn: 'root',
})
export class SignupNavigationService extends Store<ISignupStore> {
    constructor(private router: Router, private userService: UserService) {
        super(INITIAL_VALUES);
    }

    nextStep(): void {
        const urlTree = this.router.parseUrl(this.router.url);
        const segments = urlTree.root.children[PRIMARY_OUTLET].segments.map((seg) => seg.path);
        const baseUrl = `/${segments.join('/')}`;

        if (this.userService.hasRole(EUserRole.B2C)) {
            const currentStep = b2cUserSteps.findIndex((url) => url === baseUrl);
            this.router.navigateByUrl(b2cUserSteps[currentStep + 1]);
        } else {
            const currentStep = b2bUserSteps.findIndex((url) => url === baseUrl);
            this.router.navigateByUrl(b2bUserSteps[currentStep + 1]);
        }
    }

    identifyUserStep(): void {
        this.router.navigateByUrl(B2C_IDENTIFICATION_STEP_URL);
    }

    generateKeysStep(): void {
        this.router.navigateByUrl(B2C_KEYS_GENERATION_STEP_URL);
    }

    documentVerificationStep(): void {
        this.router.navigateByUrl(B2C_DOCUMENT_VERIFICATION);
    }
}

import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mapTo, pluck, switchMap, tap } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';

import { MultiFactorAuthMethod } from '@proto/auth/multi-factor-auth.enum_pb';
import { NgxPermissionsService } from 'ngx-permissions';

import { IServiceError } from '@grpc/interfaces/common/service-error.interface';
import { UserGrpcService } from '@grpc/services/account/user.grpc.service';

import { Company } from '@proto/account/company_pb';
import { Role } from '@proto/account/role.enum_pb';
import { UserAccountType } from '@proto/account/user-type.enum_pb';
import { ReadResponse, UserProfile } from '@proto/account/user_pb';

import { EUserRole } from '@share/enums/role.enum';
import { Store } from '@share/helpers/store';
import { ENotificationMessageTypes, NotificationService } from '@share/services/notification.service';

export interface IUserStore {
    user: ReadResponse.AsObject | null;
}

const INITIAL_USER_STORE: IUserStore = {
    user: null,
};

@Injectable({
    providedIn: 'root',
})
export class UserService extends Store<IUserStore> {
    is2FaDone$: Observable<boolean> = this.getProperty('user').pipe(
        filter((user) => Boolean(user)),
        pluck('isenabledmfa'),
        distinctUntilChanged(),
    );

    userAuthMethod$: Observable<MultiFactorAuthMethod> = this.getProperty('user').pipe(
        filter((user) => Boolean(user) && !user.isenabledmfa),
        pluck('methodmfa'),
        distinctUntilChanged(),
    );

    refreshUserFailed$ = new Subject<void>();

    private _walletSecretKey: string;

    get walletSecretKey(): string {
        return this._walletSecretKey;
    }

    set walletSecretKey(value: string) {
        this._walletSecretKey = value;
    }

    constructor(
        private _userGrpcService: UserGrpcService,
        private _logger: NGXLogger,
        private _notificationService: NotificationService,
        private _permissionService: NgxPermissionsService,
    ) {
        super(INITIAL_USER_STORE);
    }

    getUser(): Observable<ReadResponse.AsObject> {
        return this.getProperty('user').pipe(filter<ReadResponse.AsObject>(Boolean));
    }

    getUserSync(): ReadResponse.AsObject {
        return this.state.user;
    }

    getRefreshUser(): Observable<ReadResponse.AsObject> {
        return this.refreshUser().pipe(
            switchMap(() => this.getUser().pipe(filter<ReadResponse.AsObject>(Boolean))),
            catchError((err: IServiceError) => {
                this._logger.error(err);
                this._notificationService.create({
                    type: ENotificationMessageTypes.ERROR,
                    content: 'errors.REFRESH_USER_FAILED',
                });
                this.refreshUserFailed$.next();
                throw err;
            }),
        );
    }

    refreshUser(): Observable<void> {
        return this._userGrpcService.getUserData().pipe(
            tap((user: ReadResponse.AsObject) => {
                this._setUserRoles(user);
                this.setState({ user });
            }),
            mapTo(null),
        );
    }

    getUserProfile(): Observable<UserProfile.AsObject> {
        return this._userGrpcService.getUserProfileData();
    }

    hasRole(role: EUserRole): boolean {
        return this._permissionService.getPermission(role) !== undefined;
    }

    private _setUserRoles(user: ReadResponse.AsObject): void {
        const roles: EUserRole[] = [];

        if (user.useraccounttype === UserAccountType.B2B) {
            roles.push(EUserRole.B2B);
            roles.push(...this._getCompanyPermissions(user.company));
        } else {
            roles.push(EUserRole.B2C);
        }

        if (user.role === Role.ADMIN) {
            roles.push(EUserRole.COMPANY_ADMIN);
        }

        this._permissionService.loadPermissions(roles);
    }

    private _getCompanyPermissions(company: Company.AsObject): EUserRole[] {
        const companyRoles: EUserRole[] = [];

        if (company.issuer) {
            companyRoles.push(EUserRole.ISSUER);
        }

        if (company.validator) {
            companyRoles.push(EUserRole.VALIDATOR);
        }

        return companyRoles;
    }
}

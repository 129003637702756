import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { KycStatus } from '@proto/account/kyc-status.enum_pb';

import { ConfigStoreService } from '@share/services/config-store.service';
import { SignupNavigationService } from '@share/services/signup-navigation.service';
import { UserService } from '@share/services/user.service';
import { EUserRole } from '@share/enums/role.enum';

@Injectable({
    providedIn: 'root',
})
export class KycVerifiedGuard implements CanActivate {
    constructor(
        private configStoreService: ConfigStoreService,
        private signupNavigationService: SignupNavigationService,
        private userService: UserService,
    ) {}

    canActivate(): Observable<boolean> {
        return combineLatest([this.userService.getUser(), this.configStoreService.isDevOrDebugMode()]).pipe(
            take(1),
            map(([user, isDevOrDebugMode]) =>
                this.userService.hasRole(EUserRole.B2B) || isDevOrDebugMode ? true : user?.kyc === KycStatus.VERIFIED,
            ),
            tap((verified) => {
                if (!verified) {
                    this.signupNavigationService.identifyUserStep();
                }
            }),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class KycNotVerifiedGuard implements CanActivate {
    constructor(
        private configStoreService: ConfigStoreService,
        private userService: UserService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean> {
        return combineLatest([this.userService.getUser(), this.configStoreService.isDevOrDebugMode()]).pipe(
            take(1),
            map(([user, isDevOrDebugMode]) => (isDevOrDebugMode ? true : user?.kyc !== KycStatus.VERIFIED)),
            tap((notVerified) => {
                if (!notVerified) {
                    this.router.navigate(['']);
                }
            }),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class KycNotRevokedGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService) {}

    canActivate(): Observable<boolean> {
        return this.userService.getUser().pipe(
            take(1),
            map((user) => user?.kyc !== KycStatus.REVOKED),
            tap((notRevoked) => {
                if (!notRevoked) {
                    this.router.navigate(['/profile'], {
                        state: {
                            forcedKyc: true,
                        },
                    });
                }
            }),
        );
    }
}

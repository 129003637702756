import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@share/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class NotAuthGuard implements CanActivate {
    constructor(private _router: Router, private _authService: AuthService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._authService.isLoggedIn().pipe(
            map((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    return true;
                }

                return this._router.parseUrl('/profile');
            }),
        );
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Translation, TranslocoLoader, TRANSLOCO_LOADER } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class HttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(langPath: string): Observable<Translation> {
        return this.http.get<Translation>(`assets/i18n/${langPath}.json`);
    }
}

export const translocoLoaderService = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

import { AmountComponent } from '@share/modules/amount/components/amount/amount.component';
import { PipesModule } from '@share/pipes/pipes.module';

import { AmountInputComponent } from './components/amount-input/amount-input.component';
import { AmountPipe } from './pipes/amount.pipe';

@NgModule({
    declarations: [AmountInputComponent, AmountComponent, AmountPipe],
    exports: [AmountInputComponent, AmountComponent, AmountPipe],
    imports: [CommonModule, PipesModule, FormsModule, ReactiveFormsModule, NzInputNumberModule],
})
export class AmountModule {}

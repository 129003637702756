import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Metadata } from 'grpc-web';

import { GrpcDevTool } from '@grpc/helpers/grpc-dev-tool';
import { grpcHash } from '@grpc/helpers/grpc-hash';
import { grpcMetadata } from '@grpc/helpers/grpc-metadata';
import { grpcUnary } from '@grpc/helpers/grpc-unary';
import { IGrpcConfigInterface } from '@grpc/interfaces/grpc-config.interface';
import { GrpcConfigService } from '@grpc/services/grpc-config.service';

import { AuthServicePromiseClient } from '@proto/auth/auth_grpc_web_pb';
import {
    AuthorizeRequestB2B,
    AuthorizeRequestB2C,
    ChangePasswordRequest,
    CheckQRAuthorizeB2CRequest,
    CheckQRAuthorizeB2CResponse,
    ConfirmPasswordRequest,
    ForgotPasswordB2BRequest,
    ForgotPasswordB2CRequest,
    ResponseWithTokenAndPublicKey,
} from '@proto/auth/auth_pb';
import { Empty } from '@proto/common/web-empty_pb';

@Injectable({
    providedIn: 'root',
})
export class AuthGrpcService {
    @GrpcDevTool
    private _client: AuthServicePromiseClient = new AuthServicePromiseClient(this._config.url);

    constructor(@Inject(GrpcConfigService) private _config: IGrpcConfigInterface) {}

    checkQrAuthorizeB2C(requestId: string): Observable<CheckQRAuthorizeB2CResponse.AsObject> {
        const req: CheckQRAuthorizeB2CRequest = new CheckQRAuthorizeB2CRequest();
        const meta: Metadata = grpcMetadata();

        req.setId(requestId);

        return grpcUnary<CheckQRAuthorizeB2CResponse.AsObject>(() => this._client.checkQRAuthorizeB2C(req, meta));
    }

    authorizeB2B(
        email: string,
        password: string,
        code: string = null,
    ): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        const req: AuthorizeRequestB2B = new AuthorizeRequestB2B();
        const meta: Metadata = grpcMetadata();

        req.setEmail(email);
        req.setPassword(grpcHash(password));
        req.setVerificationcode(code);

        return grpcUnary<ResponseWithTokenAndPublicKey.AsObject>(() => this._client.authorizeB2B(req, meta));
    }

    authorizeB2C(phone: string, password: string, code?: string): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        const req: AuthorizeRequestB2C = new AuthorizeRequestB2C();
        const meta: Metadata = grpcMetadata();

        req.setPhonenumber(`+${phone}`);
        req.setPassword(grpcHash(password));

        if (code) {
            req.setVerificationcode(code);
        }

        return grpcUnary<ResponseWithTokenAndPublicKey.AsObject>(() => this._client.authorizeB2C(req, meta));
    }

    renewAuthorization(token: string): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        const req: Empty = new Empty();
        const meta: Metadata = grpcMetadata(token);

        return grpcUnary<ResponseWithTokenAndPublicKey.AsObject>(() => this._client.renewAuthorization(req, meta));
    }

    forgotPasswordByEmail(email: string): Observable<Empty.AsObject> {
        const req: ForgotPasswordB2BRequest = new ForgotPasswordB2BRequest();
        const meta: Metadata = grpcMetadata();

        req.setEmail(email);

        return grpcUnary<Empty.AsObject>(() => this._client.forgotPasswordB2B(req, meta));
    }

    forgotPasswordByPhone(phone: string, passportLastDigits: string, code?: string): Observable<Empty.AsObject> {
        const req: ForgotPasswordB2CRequest = new ForgotPasswordB2CRequest();
        const meta: Metadata = grpcMetadata();

        req.setPhonenumber(`+${phone}`);
        req.setDocnumberlastfourdigits(passportLastDigits);

        if (code) {
            req.setVerificationcode(code);
        }

        return grpcUnary<Empty.AsObject>(() => this._client.forgotPasswordB2C(req, meta));
    }

    changePassword(token: string, password: string): Observable<Empty.AsObject> {
        const req: ChangePasswordRequest = new ChangePasswordRequest();
        const meta: Metadata = grpcMetadata(token);

        req.setPassword(grpcHash(password));

        return grpcUnary<Empty.AsObject>(() => this._client.changePassword(req, meta));
    }

    confirmPassword(
        oldPass: string,
        newPass: string,
        code?: string,
    ): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        const req: ConfirmPasswordRequest = new ConfirmPasswordRequest();
        const meta: Metadata = grpcMetadata();

        req.setOldpassword(grpcHash(oldPass));
        req.setNewpassword(grpcHash(newPass));
        if (code) {
            req.setVerificationcode(code);
        }

        return grpcUnary<ResponseWithTokenAndPublicKey.AsObject>(() => this._client.confirmPassword(req, meta));
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2eLocatorDirective } from './directives/e2e-locator.directive';

@NgModule({
    declarations: [E2eLocatorDirective],
    imports: [CommonModule],
    exports: [E2eLocatorDirective],
})
export class E2eHelperModule {}

import { IIssuancesVolumeChartConfig } from '@share/interfaces/chart.interface';

export const ISSUANCES_VOLUME_CHART: IIssuancesVolumeChartConfig = {
    textStyle: {
        fontFamily: 'Flama',
        fontSize: 16,
    },
    series: {
        colors: ['#BAC8A7', '#7C7F8A'],
        style: {
            initialOpacity: 0.25,
            hoverOpacity: 1,
        },
    },
    axis: {
        labelMargin: 15,
        x: {
            timeInterval: 6,
        },
        y: {
            valueInterval: 4,
        },
    },
};

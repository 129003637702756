import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { formatDistance } from 'date-fns';
import { DateHelpers } from '@core/date';
import ru from 'date-fns/locale/ru';

@Pipe({
    name: 'timeDistance',
})
export class TimeDistancePipe implements PipeTransform {
    transform(value: Timestamp.AsObject): unknown {
        return formatDistance(DateHelpers.timestampToDate(value), new Date(), { addSuffix: true, locale: ru });
    }
}

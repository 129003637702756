import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppInjectorService {
    private static _instance: AppInjectorService;

    constructor(private _injector: Injector) {
        AppInjectorService._instance = this;
    }

    static getInstance(): AppInjectorService {
        // Commented (22.06.2020) due to unit test error (throw error then AppInjectorService is not instantiated
        // if (!AppInjectorService._instance) {
        //     throw new Error('AppInjectorService not initialized');
        // }

        return AppInjectorService._instance;
    }

    // set to unknown to get not only services but classes as ComponentFactoryResolver too
    static getServiceInstance<T>(type: unknown): T {
        try {
            return AppInjectorService.getInstance()?._injector.get(type);
        } catch (err) {
            // TODO: temp solution, to avoid Error: Injector has already been destroyed.
        }
    }
}

import { Metadata } from 'grpc-web';

import { environment } from '@environments/environment';

import { DEVICE_INFO_NAME } from '@core/configs';

import { IDeviceInfo } from '@share/interfaces/config.interface';

interface IParamsMetadataWithMfaAndSignature {
    code?: string;
    signature?: string;
    nonce?: string;
}

const NULLABLE_DEVICE_INFO: Readonly<IDeviceInfo> = {
    platform: null,
    browser: null,
    operationSystem: null,
};

export function grpcMetadata(token: string = null): Metadata {
    let authorization: string = getAuthorization(token);
    let device: string = getDevice();
    let deviceInfo: IDeviceInfo = getDeviceInfo();
    return {
        Authorization: authorization,
        Device: device,
        Platform: deviceInfo.platform,
        OS: deviceInfo.operationSystem,
        Browser: deviceInfo.browser,
    };
}

export function grpcMobileMetadata(): Metadata {
    const deviceInfo: IDeviceInfo = getDeviceInfo();
    return {
        userIp: '127.0.0.1',
        url: 'localhost',
        device: getDevice(),
        platform: deviceInfo.platform,
        os: deviceInfo.operationSystem,
        browser: deviceInfo.browser,
    };
}

export function grpcMetadataWithMfaAndSignature(params?: IParamsMetadataWithMfaAndSignature) {
    const { code, signature, nonce } = params;
    const meta: Metadata = grpcMetadata();

    if (code) {
        meta['mfa-code'] = code;
    }

    if (signature) {
        meta['x-signatures'] = signature;
        // Remove when OTF backend gets ready
        meta['x-signature'] = signature;
    }

    if (nonce) {
        meta['x-nonce'] = nonce;
    }

    // TODO Huge kludge!!! Remove once all demos have been conducted!!!
    if (window['checksum']) {
        meta['checksum'] = window['checksum'];
    }

    return meta;
}

function getAuthorization(token: string): string {
    let authorization: string;

    try {
        authorization = token || JSON.parse(localStorage.getItem(environment.token)) || null;
    } catch (err) {
        authorization = null;
    }

    return authorization;
}

function getDevice(): string {
    let device: string;

    try {
        device = JSON.parse(localStorage.getItem(environment.fingerprint)) || null;
    } catch (err) {
        device = null;
    }

    return device;
}

function getDeviceInfo(): IDeviceInfo {
    let deviceInfo: IDeviceInfo;

    try {
        deviceInfo = JSON.parse(localStorage.getItem(DEVICE_INFO_NAME)) || NULLABLE_DEVICE_INFO;
    } catch (err) {
        deviceInfo = NULLABLE_DEVICE_INFO;
    }

    return deviceInfo;
}

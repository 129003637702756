import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { SignupNavigationService } from '@share/services/signup-navigation.service';
import { UserService } from '@share/services/user.service';

function userHasWallet(userService: UserService): Observable<boolean> {
    return userService.getUser().pipe(
        take(1),
        map((user) => Boolean(user) && Boolean(user.wallet)),
    );
}

@Injectable({
    providedIn: 'root',
})
export class WalletExistsGuard implements CanActivate {
    constructor(private _userService: UserService, private _router: Router) {}

    canActivate(): Observable<boolean> {
        return userHasWallet(this._userService).pipe(
            map((hasWallet) => !hasWallet),
            tap((hasNoWallet) => {
                if (!hasNoWallet) {
                    this._router.navigate(['profile']);
                }
            }),
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class WalletNotExistsGuard implements CanActivate {
    constructor(private _signupNavigationService: SignupNavigationService, private _userService: UserService) {}

    canActivate(): Observable<boolean> {
        return userHasWallet(this._userService).pipe(
            tap((hasWallet) => {
                if (!hasWallet) {
                    this._signupNavigationService.generateKeysStep();
                }
            }),
        );
    }
}

import { ModuleWithProviders, NgModule } from '@angular/core';

import { IGrpcConfigInterface } from './interfaces/grpc-config.interface';
import { GrpcConfigService } from './services/grpc-config.service';

@NgModule()
export class GrpcModule {
    static forRoot(config: IGrpcConfigInterface): ModuleWithProviders<GrpcModule> {
        return {
            ngModule: GrpcModule,
            providers: [
                {
                    provide: GrpcConfigService,
                    useValue: config,
                },
            ],
        };
    }
}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxPermissionsModule } from 'ngx-permissions';

import { AuthInterceptor } from '@core/interceptors/auth.interceptor';

import { ShareModule } from '@share/share.module';

import { HeaderComponent } from './components/header/header.component';
import { MOCK } from './configs';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
    declarations: [HeaderComponent, NotificationsComponent],
    exports: [HeaderComponent],
    imports: [CommonModule, ShareModule, RouterModule, NgxPermissionsModule.forChild()],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            // INFO: to turn on mocked data
            provide: MOCK,
            useValue: true,
        },
    ],
})
export class CoreModule {}

export function GrpcDevTool(target, key) {
    const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
    let value = target[key];
    const setter = (newVal) => {
        try {
            enableDevTools([newVal]);
        } catch (e) {
            console.error(`${key} is not grpc client instance`);
        }
        value = newVal;
    };
    Object.defineProperty(target, key, {
        get: () => value,
        set: setter,
        enumerable: true,
        configurable: true,
    });
}

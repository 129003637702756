export enum ETermsLinkType {
    ISUSERPUBLICOFFICIAL = 'isuserpublicofficial',
    ISUSERHASRELATIVEPUBLICOFFICIAL = 'isuserhasrelativepublicofficial',
    ISUSERBENEFICIALOWNERSHIP = 'isuserbeneficialownership',
    ISPLANNEDOPERATIONSFORPDL = 'isplannedoperationsforpdl',
    ISPLANNEDOPERATIONSFORTHIRDPARTIES = 'isplannedoperationsforthirdparties',
    ISAGREERULESDOCUMENTMANAGEMENT = 'isagreerulesdocumentmanagement',
    ISAGREEPROCESSINGPERSONALDATA = 'isagreeprocessingpersonaldata',
    SYSTEM = 'system',
    PD = 'pd',
    RISKS = 'risk',
    CONNECTION = 'connection',
    CHANGE = 'change',
    RULES = 'rules',
    DOCUMENT_AUTOMATION = 'document_automation',

    KVAL_DOC_B2B = 'kval_request_document_b2b',
    NO_KVAL_DOC_B2B = 'no_kval_request_document_b2b',
    DECLARATION_B2C = 'declaration_b2c',
}

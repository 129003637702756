import { Injectable } from '@angular/core';

import { STORAGE_NAMES } from '@core/configs';

import { KeyType } from '@proto/account/key-type.enum_pb';

import { HlfHelpers } from '@share/helpers/hlf-helpers';

import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class KeysService {
    constructor(private storageService: StorageService) {}

    getPublicKey(): string {
        return this.storageService.last(STORAGE_NAMES.storagePublicKeyName);
    }

    getHlfAddress(publicKey?: string): string {
        return HlfHelpers.toHlfAddress(publicKey || this.getPublicKey());
    }

    // TODO: This is for testing purposes only, remove when key management is implemented on backend!
    getMockSecretKeyOrNull(): string | null {
        const publicKey: string = this.storageService.last('publicKey');

        return publicKey ? this.storageService.last(publicKey) : this.storageService.last('secretKey');
    }

    hasPublicKey(): boolean {
        return !!this.storageService.last(STORAGE_NAMES.storagePublicKeyName);
    }

    updateKeyInfo(publicKey: string, keyType: KeyType): void {
        !!publicKey
            ? this.storageService.push(STORAGE_NAMES.storagePublicKeyName, publicKey)
            : this.storageService.remove(STORAGE_NAMES.storagePublicKeyName);

        !!keyType
            ? this.storageService.push(STORAGE_NAMES.storageKeyTypeName, keyType)
            : this.storageService.remove(STORAGE_NAMES.storageKeyTypeName);
    }

    clearKeyInfo(): void {
        this.storageService.remove(STORAGE_NAMES.storagePublicKeyName);
        this.storageService.remove(STORAGE_NAMES.storageKeyTypeName);
    }
}

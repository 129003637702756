import { ClipboardModule } from '@angular/cdk/clipboard';
import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ru from '@angular/common/locales/ru';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ru_RU, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';

import { LoggerModule } from 'ngx-logger';

import { TranslocoModule } from '@ngneat/transloco';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';

import { environment } from '@environments/environment';

import { LOGGER_CONFIG } from '@core/configs';

import { GrpcModule } from '@grpc/grpc.module';

import * as components from '@share/components';
import * as directives from '@share/directives';
import * as modules from '@share/modules';
import { PipesModule } from '@share/pipes/pipes.module';

import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { DevModWrapperComponent } from './components/dev-mod-wrapper/dev-mod-wrapper.component';

registerLocaleData(ru);

@NgModule({
    declarations: [...components.list, ...directives.list, CustomDatePickerComponent, DevModWrapperComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        PipesModule,
        ClipboardModule,
        GrpcModule.forRoot({ url: environment.url }),
        LoggerModule.forRoot(LOGGER_CONFIG),
        DeviceDetectorModule.forRoot(),
        NgxPermissionsModule.forChild(),
        ...modules.list,
    ],
    providers: [
        { provide: NZ_I18N, useValue: ru_RU },
        { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        ClipboardModule,
        PipesModule,
        ...modules.list,
        ...components.list,
        ...directives.list,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShareModule {}

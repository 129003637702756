import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Metadata } from 'grpc-web';

import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { GrpcDevTool } from '@grpc/helpers/grpc-dev-tool';
import { grpcHash } from '@grpc/helpers/grpc-hash';
import { grpcMetadata, grpcMetadataWithMfaAndSignature } from '@grpc/helpers/grpc-metadata';
import { grpcUnary } from '@grpc/helpers/grpc-unary';
import { IGrpcConfigInterface } from '@grpc/interfaces/grpc-config.interface';
import { GrpcConfigService } from '@grpc/services/grpc-config.service';

import { RegisterServicePromiseClient } from '@proto/account/register_grpc_web_pb';
import {
    Agreements,
    CheckB2cEmailVerificationRequest,
    CheckB2cEmailVerificationResponse,
    CheckEmailExistenceRequest,
    CheckPhoneNumberExistenceRequest,
    Confirm2FAForB2CAgreementsRequest,
    DeactivateUserRequest,
    RegistrationB2BRequest,
    SendEmailVerificationRequest,
    SetAdditionalInformationB2CRequest,
    StartB2cEmailVerificationRequest,
    TempRegistrationB2CRequest,
    UserDeactivatingPossibility,
    UserDeactivatingResult,
} from '@proto/account/register_pb';
import { Empty } from '@proto/common/web-empty_pb';

import { DateHelpers } from '@core/date';

@Injectable({
    providedIn: 'root',
})
export class RegistrationGrpcService {
    @GrpcDevTool
    private _client: RegisterServicePromiseClient = new RegisterServicePromiseClient(this._config.url);

    constructor(@Inject(GrpcConfigService) private _config: IGrpcConfigInterface) {}

    registrationB2B(email: string, password: string, invite: string): Observable<Empty.AsObject> {
        const req: RegistrationB2BRequest = new RegistrationB2BRequest();
        const meta: Metadata = grpcMetadata();

        req.setEmail(email);
        req.setPassword(grpcHash(password));
        req.setInvitekey(invite);

        return grpcUnary<Empty.AsObject>(() => this._client.registrationB2B(req, meta));
    }

    registrationB2C(password: string): Observable<Empty.AsObject> {
        const req: TempRegistrationB2CRequest = new TempRegistrationB2CRequest();
        const meta: Metadata = grpcMetadata();

        req.setPassword(grpcHash(password));

        return grpcUnary<Empty.AsObject>(() => this._client.tempRegistrationB2C(req, meta));
    }

    checkEmailExistence(email: string): Observable<Empty.AsObject> {
        const req: CheckEmailExistenceRequest = new CheckEmailExistenceRequest();
        const meta: Metadata = grpcMetadata();

        req.setEmail(email.toLowerCase());

        return grpcUnary<Empty.AsObject>(() => this._client.checkEmailExistence(req, meta));
    }

    checkPhoneNumberExistence(phone: string): Observable<Empty.AsObject> {
        const req: CheckPhoneNumberExistenceRequest = new CheckPhoneNumberExistenceRequest();
        const meta: Metadata = grpcMetadata();

        req.setPhonenumber(`+${phone}`);

        return grpcUnary<Empty.AsObject>(() => this._client.checkPhoneNumberExistence(req, meta));
    }

    confirmUser(token: string): Observable<Empty.AsObject> {
        const req: Empty = new Empty();
        const meta: Metadata = grpcMetadata(token);

        return grpcUnary<Empty.AsObject>(() => this._client.confirmUser(req, meta));
    }

    resendConfirmation(email: string): Observable<Empty.AsObject> {
        const req: SendEmailVerificationRequest = new SendEmailVerificationRequest();
        req.setEmail(email);

        const meta: Metadata = grpcMetadata();

        return grpcUnary<Empty.AsObject>(() => this._client.sendEmailVerification(req, meta));
    }

    additionalInformationB2C(
        isuserpublicofficial: boolean,
        isuserhasrelativepublicofficial: boolean,
        isuserbeneficialownership: boolean,
        isplannedoperationsforpdl: boolean,
        isplannedoperationsforthirdparties: boolean,
    ): Observable<Empty.AsObject> {
        const req: SetAdditionalInformationB2CRequest = new SetAdditionalInformationB2CRequest();
        const meta: Metadata = grpcMetadata();

        req.setIsuserpublicofficial(isuserpublicofficial);
        req.setIsuserbeneficialownership(isuserhasrelativepublicofficial);
        req.setIsuserhasrelativepublicofficial(isuserbeneficialownership);
        req.setIsplannedoperationsforpdl(isplannedoperationsforpdl);
        req.setIsplannedoperationsforthirdparties(isplannedoperationsforthirdparties);

        return grpcUnary<Empty.AsObject>(() => this._client.setAdditionalInformationB2C(req, meta));
    }

    acceptAgreements(agreements: Agreements.AsObject): Observable<Empty.AsObject> {
        const req: Agreements = new Agreements();
        const meta: Metadata = grpcMetadata();
        const cookieTime: Timestamp = DateHelpers.timestampObjectToTimestamp(agreements.cookiesagreementdate);

        req.setPersonaldata(agreements.personaldata);
        req.setElectronicdocumentmanagement(agreements.electronicdocumentmanagement);
        req.setPlatformusageagreement(agreements.platformusageagreement);
        req.setRiskagreement(agreements.riskagreement);
        req.setRulesagreement(agreements.rulesagreement);
        req.setCookiesagreementdate(cookieTime);

        return grpcUnary<Empty.AsObject>(() => this._client.acceptAgreements(req, meta));
    }

    confirm2FAForB2CAgreements(code: string): Observable<Empty.AsObject> {
        const req: Confirm2FAForB2CAgreementsRequest = new Confirm2FAForB2CAgreementsRequest();
        const meta: Metadata = grpcMetadata();

        req.setCode(code);

        return grpcUnary<Empty.AsObject>(() => this._client.confirm2FAForB2CAgreements(req, meta));
    }

    checkUserDeactivatingPossibility(): Observable<UserDeactivatingPossibility.AsObject> {
        const req: Empty = new Empty();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<UserDeactivatingPossibility.AsObject>(() =>
            this._client.checkUserDeactivatingPossibility(req, meta),
        );
    }

    deactivateUser(code: string, signature: string, agreement: boolean): Observable<UserDeactivatingResult.AsObject> {
        const req: DeactivateUserRequest = new DeactivateUserRequest();
        const meta: Metadata = grpcMetadataWithMfaAndSignature({ code, signature });

        req.setRevokepersonaldataagreement(agreement);

        return grpcUnary<UserDeactivatingResult.AsObject>(() => this._client.deactivateUser(req, meta));
    }

    startB2cEmailVerification(email: string): Observable<Empty.AsObject> {
        const req: StartB2cEmailVerificationRequest = new StartB2cEmailVerificationRequest();
        const meta: Metadata = grpcMetadata();

        req.setEmail(email);

        return grpcUnary<Empty.AsObject>(() => this._client.startB2cEmailVerification(req, meta));
    }

    checkB2cEmailVerification(
        userId: string,
        email: string,
        code: string,
    ): Observable<CheckB2cEmailVerificationResponse.AsObject> {
        const req: CheckB2cEmailVerificationRequest = new CheckB2cEmailVerificationRequest();
        const meta: Metadata = grpcMetadata();

        req.setUserid(userId);
        req.setEmail(email);
        req.setVerificationcode(code);

        return grpcUnary<CheckB2cEmailVerificationResponse.AsObject>(() =>
            this._client.checkB2cEmailVerification(req, meta),
        );
    }
}

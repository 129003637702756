import { Injectable } from '@angular/core';

import { UserBannedComponent } from '../components/user-banned/user-banned.component';
import { UserForcedToChangePasswordComponent } from '../components/user-forced-to-change-password/user-forced-to-change-password.component';
import { EModalHeaderColors } from '../enums/modal-colors.enum';
import { DialogService } from '../modules/dialog/services/dialog.service';

import { ConfigStoreService } from './config-store.service';

@Injectable({
    providedIn: 'root',
})
export class UserDialogsService {
    private cbConfirm = null;
    constructor(private configStoreService: ConfigStoreService, private dialogService: DialogService) {}

    setConfirmCallBack(cb) {
        this.cbConfirm = cb;
    }

    showUserBannedDialog(): void {
        this.dialogService.confirm({
            title: 'user_modal.USER_BANNED.T',
            headerColor: EModalHeaderColors.RED,
            contentTemplate: UserBannedComponent,
            redirectText: 'user_modal.USER_EXIT',
            onConfirm: () => this.cbConfirm(),
            extraCssClass: 'atomyze-modal--user-dialog',
            closableMask: this.configStoreService.getIsDevSync(),
        });
    }

    showUserForcedToChangePasswordDialog(): void {
        this.dialogService.confirm({
            title: 'user_modal.USER_FORCED_TO_CHANGE_PASSWORD.T',
            headerColor: EModalHeaderColors.RED,
            contentTemplate: UserForcedToChangePasswordComponent,
            redirectText: 'user_modal.USER_EXIT',
            onConfirm: () => this.cbConfirm(),
            extraCssClass: 'atomyze-modal--user-dialog',
            closableMask: this.configStoreService.getIsDevSync(),
        });
    }
}

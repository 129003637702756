import { Injectable } from '@angular/core';
import { Documents, GetWebConfigResponse } from '@proto/admin/config_pb';
import { Store } from '@share/helpers/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IConfigStore {
    config: GetWebConfigResponse.AsObject | null;
    documents: Documents.AsObject | null;
}

const INITIAL_VALUES: IConfigStore = {
    config: null,
    documents: null,
};

@Injectable({
    providedIn: 'root',
})
export class ConfigStoreService extends Store<IConfigStore> {
    constructor() {
        super(INITIAL_VALUES);
    }

    get isDev(): Observable<boolean> {
        return this.getProperty('config').pipe(
            map((config: GetWebConfigResponse.AsObject) => {
                return config.environment === 'develop';
            }),
        );
    }

    isDevOrDebugMode(): Observable<boolean> {
        return this.getProperty('config').pipe(
            map((config: GetWebConfigResponse.AsObject) => {
                return config.environment === 'develop' || config.debugmode;
            }),
        );
    }

    getIsDevSync(): boolean {
        return this.state.config.environment === 'develop';
    }
}

import { NgModule } from '@angular/core';

import { DateLocalTimezonePipe } from '@share/pipes/date-local-timezone.pipe';
import * as pipes from '@share/pipes/index';

@NgModule({
    declarations: [...pipes.list],
    exports: [...pipes.list],
    providers: [DateLocalTimezonePipe],
})
export class PipesModule {}

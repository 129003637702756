import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TranslocoService } from '@ngneat/transloco';

import { ErrorCode } from '@proto/errors/errors.enum_pb';

import { AppInjectorService } from '@core/services/app-injector.service';

import { isGrpcError } from '@grpc/helpers/is-grpc-error.helper';
import { IServiceError } from '@grpc/interfaces/common/service-error.interface';

import { ENotificationMessageTypes, NotificationService } from '@share/services/notification.service';

export function handleAuthErrors<T>(source: Observable<T>) {
    const notificationService: NotificationService = AppInjectorService.getServiceInstance(NotificationService);
    const translateService: TranslocoService = AppInjectorService.getServiceInstance(TranslocoService);

    return source.pipe(
        catchError((err: IServiceError) => {
            if (isGrpcError(err)) {
                switch (err.code) {
                    case ErrorCode.USER_IS_BANNED_MFA_BRUTEFORCE:
                        const minutes: number = Math.ceil(Number(err.metadata.mfa_ban_ttl) / 60);
                        notificationService.create({
                            type: ENotificationMessageTypes.ERROR,
                            translatedText: translateService.translate('auth-lg.ERRORS.MFA_ATTEMPTS_BANNED', {
                                minutes,
                            }),
                        });
                        break;
                    case ErrorCode.ALREADY_EXIST:
                        notificationService.create({
                            type: ENotificationMessageTypes.ERROR,
                            content: 'registration-b2c.USER_ALREADY_EXISTS',
                        });
                        break;
                }
            }
            this._logger.error(err);
            return EMPTY;
        }),
    );
}

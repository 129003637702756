import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IFingerprintComponent } from '@share/interfaces/dev-fingerprint.interface';
import { DevFingerprintService } from '@share/services/dev-fingerprint.service';

@Injectable({
    providedIn: 'root',
})
export class FingerPrintService {
    constructor(private devFingerprintService: DevFingerprintService) {}

    readDevFingerprint(): Observable<IFingerprintComponent[]> {
        return this.devFingerprintService.getFingerprint();
    }
}

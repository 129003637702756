import { Inject, Injectable } from '@angular/core';
import { GetAppConfigResponse, GetWebConfigRequest, GetWebConfigResponse } from '@proto/admin/config_pb';
import { GrpcDevTool } from '@grpc/helpers/grpc-dev-tool';
import { grpcMetadata } from '@grpc/helpers/grpc-metadata';
import { grpcUnary } from '@grpc/helpers/grpc-unary';
import { IGrpcConfigInterface } from '@grpc/interfaces/grpc-config.interface';
import { ConfigServicePromiseClient } from '@proto/admin/config_grpc_web_pb';
import { Metadata } from 'grpc-web';
import { Observable } from 'rxjs';
import { GrpcConfigService } from '../grpc-config.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigGrpcService {
    @GrpcDevTool
    private _client: ConfigServicePromiseClient = new ConfigServicePromiseClient(this._config.url);

    constructor(@Inject(GrpcConfigService) private _config: IGrpcConfigInterface) {}

    getWebConfig(): Observable<GetWebConfigResponse.AsObject> {
        const req: GetWebConfigRequest = new GetWebConfigRequest();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<GetWebConfigResponse.AsObject>(() => this._client.getWebConfig(req, meta));
    }

    getAppConfig(): Observable<GetAppConfigResponse.AsObject> {
        const req: GetWebConfigRequest = new GetWebConfigRequest();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<GetAppConfigResponse.AsObject>(() => this._client.getAppConfig(req, meta));
    }
}

import { defer, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Empty } from '@proto/common/web-empty_pb';

import { handleGrpcError } from '@share/helpers/handle-grpc-error';

interface IResponseData<T> {
    toObject: () => T;
}

export function grpcUnary<T>(promiseFunc: () => Promise<IResponseData<T> | Empty>): Observable<T> {
    return defer(() => from(promiseFunc())).pipe(
        map((response: IResponseData<T>) => response?.toObject()),
        handleGrpcError(),
    );
}

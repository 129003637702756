import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Metadata } from 'grpc-web';

import {
    MarkAsReadRequest,
    MarkAsReadResponse,
    Notification,
    NotificationsList,
    SendNotificationRequest,
    SendNotificationResponse,
} from '@proto/account/notifications_pb';
import { NotificationsServicePromiseClient } from '@proto/account/notifications_grpc_web_pb';
import { Empty } from '@proto/common/web-empty_pb';

import { grpcUnary } from '@grpc/helpers/grpc-unary';
import { grpcStream } from '@grpc/helpers/grpc-stream';
import { GrpcDevTool } from '@grpc/helpers/grpc-dev-tool';
import { IGrpcConfigInterface } from '@grpc/interfaces/grpc-config.interface';
import { grpcMetadata } from '@grpc/helpers/grpc-metadata';

import { GrpcConfigService } from '../grpc-config.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationGrpcService {
    @GrpcDevTool
    private _client: NotificationsServicePromiseClient = new NotificationsServicePromiseClient(this._config.url);

    constructor(@Inject(GrpcConfigService) private _config: IGrpcConfigInterface, private _logger: NGXLogger) {}

    openChannel(): Observable<Notification.AsObject> {
        const req: Empty = new Empty();

        return grpcStream<Notification.AsObject>(this._client, 'subscribeNotificationsFeed', req, this._logger, true);
    }

    sendNotification(title: string, text: string): Observable<SendNotificationResponse.AsObject> {
        const req: SendNotificationRequest = new SendNotificationRequest();
        const meta: Metadata = grpcMetadata();

        req.setTitle(title);
        req.setText(text);

        return grpcUnary<SendNotificationResponse.AsObject>(() => this._client.sendNotification(req, meta));
    }

    getNotificationsList(): Observable<NotificationsList.AsObject> {
        const req = new Empty();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<NotificationsList.AsObject>(() => this._client.getNotificationsList(req, meta));
    }

    markAsRead(id: string): Observable<MarkAsReadResponse.AsObject> {
        const req = new MarkAsReadRequest();
        const meta: Metadata = grpcMetadata();

        req.setNotificationid(id);

        return grpcUnary<MarkAsReadResponse.AsObject>(() => this._client.markAsRead(req, meta));
    }
}

import { ChangeDetectionStrategy, Component, OnInit, PipeTransform } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { TranslocoService } from '@ngneat/transloco';

interface IBreadcrumb {
    name: string;
    path?: string;
    pipe?: string;
}

@Component({
    selector: 'atm-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnInit {
    breadcrumbs$: BehaviorSubject<IBreadcrumb[]> = new BehaviorSubject<IBreadcrumb[]>([]);

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _translateService: TranslocoService,
    ) {}

    ngOnInit() {
        const links: IBreadcrumb[] = this._getRouteBreadcrumbs(this._activatedRoute.firstChild);
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                filter(() => !!this._activatedRoute.firstChild),
                map(() => this._getRouteBreadcrumbs(this._activatedRoute.firstChild)),
                startWith(links),
            )
            .subscribe((data) => this.breadcrumbs$.next(data));
    }

    private _getRouteBreadcrumbs(route: ActivatedRoute): IBreadcrumb[] {
        let ar: IBreadcrumb[] = [];
        if (!route || !route.routeConfig) {
            return [];
        }

        const { data } = route.routeConfig;

        if (data && data.breadcrumb) {
            let name = data.breadcrumb;
            // if starts with : then get from url params
            if (name.startsWith(':')) {
                const param = route.snapshot.paramMap.get(name.replace(':', ''));
                name = param ? param : name;
            } else {
                name = this._translateService.translate(name, route.snapshot.params);
            }

            // if pipe setted
            if (data.breadcrumbPipe && 'transform' in data.breadcrumbPipe) {
                name = (data.breadcrumbPipe as PipeTransform).transform(name);
            }

            ar.push({
                path: this._getFullLink(route),
                name,
            });
        }
        if (route.firstChild) {
            ar = [...ar, ...this._getRouteBreadcrumbs(route.firstChild)];
        }

        return ar;
    }

    private _getFullLink(route: ActivatedRoute): string {
        return route.pathFromRoot
            .filter((item) => item.snapshot.url.length)
            .map((item) => item.snapshot.url.join('/'))
            .join('/');
    }
}

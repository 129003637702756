import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class UseDeskService {
    script;
    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    checkUser(signature: string) {
        this.script.onload = () => {
            return window.usedeskMessenger.userIdentify({ signature });
        };
    }

    loadScript(src: string) {
        this._removeScript();
        this.script = this.renderer.createElement('script');
        this.renderer.appendChild(this.document.body, this.script);
        this.script.src = src;
        this.script.id = 'usedeskId';
    }

    private _removeScript() {
        const getScript = document.getElementById('usedeskId');
        const deskList = document.querySelectorAll('[id = "usedesk-messenger"]');
        getScript && getScript.parentNode.removeChild(getScript);

        deskList.forEach((item) => {
            item.parentNode.removeChild(item);
        });
    }
}

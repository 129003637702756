export const environment = {
    production: true,
    authDiff: 60,
    url: connectionConfig.backendUrl,
    oauth: {
        rosbank: {
            uri: 'https://rbgwapitest.rosbank.ru/oauth2/authorize',
            clientId: 'VdwnaByD1XWVHmQwLKTtR9zwEm0a',
        },
    },
    token: 'token',
    fingerprint: 'fingerprint',
    deviceInfo: 'deviceInfo',
    captcha_key: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};

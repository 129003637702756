import { NgxLoggerLevel, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';

export class LoggerMonitor implements NGXLoggerMonitor {
    onLog(log: NGXLogInterface) {
        const msg = `${log.message} [${log.fileName}:${log.lineNumber}]`;
        /* eslint-disable no-console */
        if (log.level === NgxLoggerLevel.ERROR) {
            console.error(`ERROR: ${msg}`);
        } else if (log.level === NgxLoggerLevel.FATAL) {
            console.error(`FATAL: ${msg}`);
        } else if (log.level === NgxLoggerLevel.WARN) {
            console.warn(`WARNING: ${msg}`);
        } else if (log.level === NgxLoggerLevel.INFO) {
            console.info(`INFO: ${msg}`);
        } else if (log.level === NgxLoggerLevel.DEBUG) {
            console.log(`DEBUG: ${msg}`);
        } else {
            console.log(`LOGGER: ${msg}`);
        }
        /* eslint-enable no-console */
    }
}

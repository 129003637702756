import { Pipe, PipeTransform } from '@angular/core';

import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { DISPLAY_FORMAT_CONFIG } from '@core/configs';
import { DateHelpers } from '@core/date';

@Pipe({ name: 'dateLocalTimezone' })
export class DateLocalTimezonePipe implements PipeTransform {
    transform(
        value: Timestamp.AsObject | Date | string | number | null,
        format?: string | Intl.DateTimeFormatOptions,
        timezone?: string,
        locale?: string,
    ): string | null {
        if (!value) {
            return null;
        }

        if (typeof value === 'object' && 'seconds' in value && 'nanos' in value) {
            value = DateHelpers.timestampToDate(value);
        }

        const options: Intl.DateTimeFormatOptions = {
            ...((typeof format === 'string' && DISPLAY_FORMAT_CONFIG[format]) || format || {}),
            timeZone: timezone || DateHelpers.localTimezone().name,
        };

        const locales: string[] = locale ? [locale] : [...navigator.languages];
        if (!isNaN(Number(value))) {
            value = Number(value);
        }
        return new Date(value).toLocaleString(locales, options);
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '@share/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class TwoFaGuard implements CanActivate {
    constructor(private _router: Router, private _userService: UserService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._userService.is2FaDone$.pipe(
            map((is2FaDone) => {
                if (is2FaDone) {
                    return true;
                }

                return this._router.parseUrl('profile');
            }),
        );
    }
}

import * as bs58check from 'bs58check';
import { SHA3 } from 'sha3';

export class HlfHelpers {
    static toHlfAddress(address: string, encoding: BufferEncoding = 'hex'): string {
        try {
            // Will throw err if address is publicKey (not bs58 with checksum)
            bs58check.decode(address);

            return address;
        } catch (err) {
            try {
                return bs58check.encode(new SHA3(256).update(Buffer.from(address, encoding)).digest());
            } catch {
                return null;
            }
        }
    }

    static validateHlfAddress(address: string): boolean {
        return !!address && this.toHlfAddress(address) === address;
    }
}

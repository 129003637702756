import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { of, Observable } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@share/services/auth.service';
import { UserService } from '@share/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private _router: Router, private _userService: UserService) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this._authService.isLoggedIn().pipe(
            switchMap((isLoggedIn: boolean) => {
                return isLoggedIn ? this._userService.getUser().pipe(mapTo(true)) : of(false);
            }),
            tap((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this._router.navigate(['login']);
                }
            }),
        );
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, pluck, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { HIDE_HEADER_CONTENT_ON_PAGES, HIDE_LOGIN_LINK_ON_PAGES } from '@core/configs';

import { ReadResponse } from '@proto/account/user_pb';

import { EUserRole } from '@share/enums/role.enum';
import { AuthService } from '@share/services/auth.service';
import { UserService } from '@share/services/user.service';
import { NotificationsService } from '@core/services/notifications.service';
import { DeactivationStatus } from '@proto/account/deactivation-status.enum_pb';

@Component({
    selector: 'atm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('headerPanel') headerPanel: ElementRef;

    deactivationstatusTypes: typeof DeactivationStatus = DeactivationStatus;
    isLogged$: Observable<boolean> = this.authService.isLoggedIn();
    profile: ReadResponse.AsObject;
    notificationsCount$ = this.notificationService.state$.pipe(pluck('messages'));
    notificationsVisible = false;
    routerLinkActiveCls: string;

    currentUrl$: Observable<string> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event?.url),
        shareReplay(),
    );

    isHeaderEnabled$: Observable<boolean> = combineLatest([this.isLogged$, this.currentUrl$]).pipe(
        map(([isLogged, url]) => HIDE_HEADER_CONTENT_ON_PAGES.every((page) => !url.startsWith(page)) && isLogged),
    );
    isLoginLinkEnabled$: Observable<boolean> = combineLatest([this.isLogged$, this.currentUrl$]).pipe(
        map(([isLogged, url]) => !HIDE_LOGIN_LINK_ON_PAGES.includes(url) && !isLogged),
    );
    readonly roles: typeof EUserRole = EUserRole;

    private destroy$: Subject<boolean> = new Subject();

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private el: ElementRef,
        private notificationService: NotificationsService,
        private router: Router,
    ) {}

    get notificationsTopMargin(): string {
        // TODO: don't know what to do with 20, its not a global constant, need only in this case
        const marginTop = this.headerPanel.nativeElement.offsetHeight + this.headerPanel.nativeElement.offsetTop + 20;
        return `${marginTop}px`;
    }

    get notificationsRightMargin(): string {
        const parentWidth = this.el.nativeElement.parentElement.offsetWidth;
        const occupiedPanelWidth =
            this.headerPanel.nativeElement.offsetLeft + this.headerPanel.nativeElement.offsetWidth;
        return `${parentWidth - occupiedPanelWidth}px`;
    }

    ngOnInit(): void {
        this.isLogged$
            .pipe(
                switchMap((isLogged) => {
                    if (isLogged) {
                        return this.userService.getUser();
                    } else {
                        return of(null);
                    }
                }),
                takeUntil(this.destroy$),
            )
            .subscribe((profile) => {
                this.profile = profile;
                this.routerLinkActiveCls =
                    profile?.restrictions?.deactivationstatus !== DeactivationStatus.PENDING
                        ? 'header__link--selected'
                        : 'header__link--disabled';
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    toogleNotifications(): void {
        this.notificationsVisible = !this.notificationsVisible;
    }

    navigateFromProfile(): string {
        switch (this.profile.restrictions?.deactivationstatus) {
            case this.deactivationstatusTypes.PENDING: {
                return '/profile/deactivation';
            }
            case this.deactivationstatusTypes.UNDEFINED: {
                return '/profile';
            }
            case this.deactivationstatusTypes.DEACTIVATED: {
                return '/login';
            }
        }
    }
}

import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NzIconService } from 'ng-zorro-antd/icon';

import { HashMap, TranslocoConfig, TranslocoService, TRANSLOCO_CONFIG } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

import { IconDefinition } from '@ant-design/icons-angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import { environment } from '@environments/environment';

import { LANG_CONFIG } from '@core/configs';
import { CoreModule } from '@core/core.module';
import { AppInjectorService } from '@core/services/app-injector.service';

import { ConfigurationService } from '@share/services/configuration.service';
import { translocoLoaderService } from '@share/services/transloco-loader.service';
import { ShareModule } from '@share/share.module';

import { UseDeskService } from '@core/services/use-desk.service';
import * as CustomIcons from '@assets/iconsfont';
import { NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { ru as RU } from 'date-fns/locale';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function appInjectorServiceFactory(injector: Injector): Function {
    return () => new AppInjectorService(injector);
}

export function initConfigurations(configurationService: ConfigurationService): () => Promise<void> {
    return () => configurationService.initConfigurations();
}

export function initIcons(iconService: NzIconService): () => void {
    return () => {
        const customIcons: IconDefinition[] = Object.keys(CustomIcons).map((key) => ({
            name: key,
            theme: 'outline',
            icon: CustomIcons[key],
        }));
        iconService.addIcon(...customIcons);
    };
}

export function preloadTransloco(translateService: TranslocoService): () => Promise<HashMap> {
    return () => {
        translateService.setActiveLang(LANG_CONFIG.default);
        return translateService.load(LANG_CONFIG.default).toPromise();
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        CoreModule,
        ShareModule,
        TranslocoMessageFormatModule.init({
            locales: LANG_CONFIG.available,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initConfigurations,
            deps: [ConfigurationService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: preloadTransloco,
            deps: [TranslocoService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initIcons,
            deps: [NzIconService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInjectorServiceFactory,
            deps: [Injector],
            multi: true,
        },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                availableLangs: LANG_CONFIG.available,
                defaultLang: LANG_CONFIG.default,
                fallbackLang: LANG_CONFIG.fallback,
                prodMode: environment.production,
            } as TranslocoConfig,
        },
        { provide: NZ_DATE_LOCALE, useValue: RU },
        translocoLoaderService,
        UseDeskService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

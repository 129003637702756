import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';

import { TranslocoModule } from '@ngneat/transloco';

import { ConfirmWithMfaB2cComponent } from '@share/modules/confirm-with-mfa/components/confirm-with-mfa-b2c/confirm-with-mfa-b2c.component';

import { E2eHelperModule } from '../../modules/e2e-helper/e2e-helper.module';
import { ConfirmWithMfaComponent } from './components/confirm-with-mfa/confirm-with-mfa.component';
import { CountDownOtpComponent } from './components/count-down-otp/count-down-otp.component';
import { HideSymbolsPipe } from './pipes/hide-symbols.pipe';

@NgModule({
    declarations: [ConfirmWithMfaComponent, CountDownOtpComponent, HideSymbolsPipe, ConfirmWithMfaB2cComponent],
    exports: [ConfirmWithMfaComponent, CountDownOtpComponent, HideSymbolsPipe, ConfirmWithMfaB2cComponent],
    imports: [
        CommonModule,
        NzSpinModule,
        NzFormModule,
        NzButtonModule,
        ReactiveFormsModule,
        NzInputModule,
        TranslocoModule,
        NzStatisticModule,
        E2eHelperModule,
    ],
})
export class ConfirmWithMfaModule {}

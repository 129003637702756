import { EchartsModule } from '@share/modules//echarts/echarts.module';
import { AmountModule } from '@share/modules/amount/amount.module';
import { ConfirmWithMfaModule } from '@share/modules/confirm-with-mfa/confirm-with-mfa.module';
import { CustodyModule } from '@share/modules/custody/custody.module';
import { E2eHelperModule } from '@share/modules/e2e-helper/e2e-helper.module';
import { ZorroAntdModule } from '@share/modules/zorro-antd/zorro-antd.module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const list: any[] = [
    E2eHelperModule,
    ConfirmWithMfaModule,
    ZorroAntdModule,
    EchartsModule,
    AmountModule,
    CustodyModule,
];

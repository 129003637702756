import { ITermsConfig, ITermMarketConfig } from '@share/interfaces/config.interface';

import { ETermsLinkType } from '../enums/terms-links-types.enums';

// TODO :: add real links
export const TERMS_LINKS: ITermsConfig = {
    [ETermsLinkType.SYSTEM]: 'assets/documents/Обязательство_о_выпуске_NFT_с_текстом_решения_29_12_21.pdf',
    [ETermsLinkType.PD]: 'assets/documents/Privacy_policy.docx',
    [ETermsLinkType.RISKS]: 'assets/documents/Risk_Warnings.docx',
    [ETermsLinkType.CONNECTION]: 'assets/documents/Platform_Privacy_Notice.pdf',
    [ETermsLinkType.CHANGE]: 'assets/documents/Terms_of_use_for_validator.docx',
    [ETermsLinkType.RULES]: 'assets/documents/Обязательство_о_выпуске_NFT_с_текстом_решения_29_12_21.pdf',
    [ETermsLinkType.DOCUMENT_AUTOMATION]: 'assets/documents/Document_Automation_Agreement.pdf',
    [ETermsLinkType.KVAL_DOC_B2B]: 'assets/documents/kval_request_b2b_form.pdf',
    [ETermsLinkType.NO_KVAL_DOC_B2B]: 'assets/documents/no_kval_request_b2b_form.pdf',
    [ETermsLinkType.DECLARATION_B2C]: 'assets/documents/declaration_b2c.pdf',
};

export const TERM_LINK_MARKET: ITermMarketConfig = {
    urlAgreement: 'assets/documents/Terms_of_Use_DRAFT.pdf',
};

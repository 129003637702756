import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ATOMYZE_SUPPORT_PHONE } from '@core/configs';

@Component({
    selector: 'atm-user-forced-to-change-password',
    templateUrl: './user-forced-to-change-password.component.html',
    styleUrls: ['./user-forced-to-change-password.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'user-forced-to-change-password',
    },
})
export class UserForcedToChangePasswordComponent implements OnInit {
    readonly atomyzeSupportPhone: string = ATOMYZE_SUPPORT_PHONE;

    constructor() {}

    ngOnInit(): void {}
}

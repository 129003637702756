import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

export class Store<T extends Object> {
    private readonly _initial: T;
    private _state$: BehaviorSubject<T>;

    constructor(initialState: T) {
        this._initial = initialState;
        this._state$ = new BehaviorSubject(initialState);
    }

    get state$(): Observable<T> {
        return this._state$.asObservable();
    }

    get state(): T {
        return this._state$.getValue();
    }

    setState(nextState: Partial<T>): void {
        this._state$.next({ ...this.state, ...nextState });
    }

    reset() {
        this._state$.next(this._initial);
    }

    getProperty<K extends keyof T>(key: K): Observable<T[K]> {
        return this._state$.pipe(pluck(key), distinctUntilChanged());
    }
}

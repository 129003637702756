import { Injectable } from '@angular/core';

import { of, Observable, Observer } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as Fingerprint2 from 'fingerprintjs2';

import { IFingerprintComponent } from '@share/interfaces/dev-fingerprint.interface';

export const FINGERPRINT_KEY_NAME = 'fingerprint';
export const ERROR_KEY_NAME = 'error';

// Commented (21-08-2019): as requestIdleCallback is experimental feature yet, it's not included in lib.d.ts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TRequestIdleCallbackHandle = any;
type TRequestIdleCallbackOptions = {
    timeout: number;
};
type TRequestIdleCallbackDeadline = {
    readonly didTimeout: boolean;
    timeRemaining: () => number;
};

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        requestIdleCallback: (
            callback: (deadline: TRequestIdleCallbackDeadline) => void,
            opts?: TRequestIdleCallbackOptions,
        ) => TRequestIdleCallbackHandle;
        cancelIdleCallback: (handle: TRequestIdleCallbackHandle) => void;
    }
}

const SEED = 37;
const DEFAULT_OPTIONS: Fingerprint2.Options = {
    preprocessor: null,
    audio: {
        timeout: 1000,
        // On iOS 11, audio context can only be used in response to user interaction.
        // We require users to explicitly enable audio fingerprinting on iOS 11.
        // See https://stackoverflow.com/questions/46363048/onaudioprocess-not-called-on-ios11#46534088
        excludeIOS11: true,
    },
    fonts: {
        swfContainerId: 'fingerprintjs2',
        swfPath: 'flash/compiled/FontList.swf',
        userDefinedFonts: [],
        extendedJsFonts: true,
    },
    screen: {
        // To ensure consistent fingerprints when users rotate their mobile devices
        detectScreenOrientation: true,
    },
    plugins: {
        sortPluginsFor: [/palemoon/i],
        excludeIE: false,
    },
    extraComponents: [],
    excludes: {
        // Unreliable on Windows, see https://github.com/Valve/fingerprintjs2/issues/375
        enumerateDevices: true,
        // devicePixelRatio depends on browser zoom, and it's impossible to detect browser zoom
        pixelRatio: true,
        // DNT depends on incognito mode for some browsers (Chrome) and it's impossible to detect incognito mode
        doNotTrack: true,
        // uses js fonts already
        fontsFlash: true,
        // gets new fingerprint when monitor changed
        screenResolution: true,
        availableScreenResolution: true,
        // potentially unstable components: inaccurate calculation leads to changing fingerprint
        // 'canvas': true,
        // 'webgl': true,
        // 'audio': true,
    },
    NOT_AVAILABLE: 'not available',
    ERROR: 'error',
    EXCLUDED: 'excluded',
};

@Injectable({
    providedIn: 'root',
})
export class DevFingerprintService {
    constructor() {}

    getFingerprint(options: Fingerprint2.Options = DEFAULT_OPTIONS): Observable<IFingerprintComponent[]> {
        const getFingerprintHash = (opts: Fingerprint2.Options, observer: Observer<IFingerprintComponent[]>) => {
            Fingerprint2.get(opts, (components: IFingerprintComponent[]) => {
                const fingerprint: string = Fingerprint2.x64hash128(JSON.stringify(components), SEED);

                observer.next([{ key: FINGERPRINT_KEY_NAME, value: fingerprint }, ...components]);

                observer.complete();
            });
        };

        return new Observable<IFingerprintComponent[]>((observer: Observer<IFingerprintComponent[]>) => {
            if (window.requestIdleCallback) {
                window.requestIdleCallback(() => {
                    getFingerprintHash(options, observer);
                });
            } else {
                setTimeout(() => {
                    getFingerprintHash(options, observer);
                }, 500);
            }
        }).pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) =>
                of([
                    { key: FINGERPRINT_KEY_NAME, value: null },
                    { key: ERROR_KEY_NAME, value: error },
                ]),
            ),
        );
    }
}

import { Router } from '@angular/router';

import { of, throwError, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { StatusCode } from 'grpc-web';

import { AppInjectorService } from '@core/services/app-injector.service';

import { isGrpcError } from '@grpc/helpers/is-grpc-error.helper';
import { IServiceError, IServiceErrorData } from '@grpc/interfaces/common/service-error.interface';

import { ErrorCode } from '@proto/errors/errors.enum_pb';

import { ENotificationMessageTypes, NotificationService } from '@share/services/notification.service';
import { UserDialogsService } from '@share/services/user-dialogs.service';

export const RPC_METHOD_NOT_IMPLEMENTED_ERROR = 12;
export type HandleGrpcErrorCodes = ErrorCode | typeof RPC_METHOD_NOT_IMPLEMENTED_ERROR;

export type HandleGrpcErrorParams = {
    [key in HandleGrpcErrorCodes]?: string; // 'string' - when there's only notification message
};

export const defaultHandleGrpcErrorParams: HandleGrpcErrorParams = {
    [RPC_METHOD_NOT_IMPLEMENTED_ERROR]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.GATEWAY_INTERNAL_ERROR]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.GATEWAY_UNAVAILABLE]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.INTERNAL_ERROR]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.INVALID_ARGUMENT]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.NOT_FOUND]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.UNAVAILABLE]: 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE',
    [ErrorCode.AMOUNT_NOT_ALLOWED]: 'handle-grpc-error.ERROR.REJECTED_TRADE',
    [ErrorCode.VALIDATION_ERRORS]: 'handle-grpc-error.ERROR.VALIDATION_ERRORS',
    [ErrorCode.TVE_REJECTED_TRADE]: 'handle-grpc-error.ERROR.REJECTED_TRADE',
};

let reIdentificationCode: string;

export function handleGrpcError() {
    const notificationService: NotificationService = AppInjectorService.getServiceInstance(NotificationService);
    const router: Router = AppInjectorService.getServiceInstance(Router);
    const userDialogsService: UserDialogsService = AppInjectorService.getServiceInstance(UserDialogsService);

    return <T>(source: Observable<T>) => {
        return source.pipe(
            switchMap((data: T) => {
                if (data?.hasOwnProperty('statuscode')) {
                    const statusCode: string = String(data['statuscode']);

                    // no error? return data
                    // FIXME: by withdraw getting status code = 3, need to clarify why not 200
                    if (statusCode === '200' || statusCode === '3') {
                        return of(data);
                    }

                    // handle error
                    let error: IServiceError;

                    switch (statusCode) {
                        case '400': {
                            error = composeServiceError(ErrorCode.INVALID_ARGUMENT, data);
                            break;
                        }
                        case '403': {
                            error = composeServiceError(ErrorCode.AMOUNT_NOT_ALLOWED, data);
                            break;
                        }
                        default:
                            error = composeServiceError(ErrorCode.ERROR_CODE_UNDEFINED, data);
                            break;
                    }

                    return throwError(error);
                }

                return of(data);
            }),
            catchError((error: IServiceError) => {
                if (isGrpcError(error)) {
                    try {
                        const errorData: IServiceErrorData = JSON.parse(error.message);

                        error.message = errorData.message;
                        error.metadata = errorData.metadata;
                    } catch (e) {}
                }

                if (error.code === StatusCode.UNAUTHENTICATED) {
                    document.dispatchEvent(new Event('InvalidJWT'));
                }

                return throwError(error);
            }),
            catchError((error: IServiceError) => {
                if (isGrpcError(error)) {
                    const isCanBeQrCodeErr =
                        error.code === ErrorCode.INTERNAL_ERROR && error.message === 'Request not found';
                    const errorMessage: string = defaultHandleGrpcErrorParams[error.code];

                    if (!isCanBeQrCodeErr && errorMessage) {
                        notificationService.create({
                            type: ENotificationMessageTypes.ERROR,
                            content: errorMessage,
                        });
                    }

                    if (error.code === ErrorCode.USER_KYC_REVOKED) {
                        // TODO: need to clarify, cause not able to navigate to profile if kyc is revoked
                        // router.navigate(['/profile'], {
                        //     state: {
                        //         forcedKyc: true,
                        //     },
                        // });

                        // TODO: find better solution
                        const location = (window as Window).location;

                        if (location.href.includes('re-identification')) {
                            const url = new URL(location.href);
                            reIdentificationCode = url.searchParams.get('code');
                        }

                        router.navigate(['/re-identification'], { queryParams: { code: reIdentificationCode } });
                    }

                    if (error.code === ErrorCode.USER_IS_BANNED) {
                        userDialogsService.showUserBannedDialog();
                    }

                    if (error.code === ErrorCode.USER_FORCED_TO_CHANGE_PASSWORD) {
                        userDialogsService.showUserForcedToChangePasswordDialog();
                    }
                }

                // throw unhandled error
                return throwError(error);
            }),
        );
    };
}

function composeServiceError<T>(code: number, data: T): IServiceError {
    return {
        code,
        message: '',
        metadata: { data: JSON.stringify(data) },
    };
}

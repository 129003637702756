import { LoggerConfig, NgxLoggerLevel } from 'ngx-logger';

import BigNumber from 'bignumber.js';

import { environment } from '@environments/environment';

import { FeeSource } from '@proto/otc/fee-source.enum_pb';

import {
    IBackConfig,
    IDisplayDateFormat,
    IDisplayFormat,
    IFeeConfig,
    IGtcExpiration,
    IKeyConfig,
    IPaginationConfig,
    IPropertyValue,
    IRetryStrategyConfig,
    ISumSubConfig,
} from '@share/interfaces/config.interface';

export const LOGGER_CONFIG: LoggerConfig = {
    level: NgxLoggerLevel.DEBUG,
    enableSourceMaps: !environment.production,
    disableConsoleLogging: false,
};

export const STORAGE_NAMES: { [id: string]: string } = {
    storageKybName: 'KYB',
    storageProfileName: 'profile',
    storageForgotPasswordTimeStamp: 'forgotPasswordTimestamp',
    storagePublicKeyName: environment['publicKey'] || 'publicKey',
    storageKeyTypeName: environment['keyType'] || 'keyType',
    storageExpiryTypeName: 'SelectedExpiryType',
    storageAcceptedAgreementsTime: 'acceptedAgreementsTime',
};

export const CONFIG: IBackConfig = {
    title: 'ATOMYZE',
    timezone: -180,
    countdownResendCode: 60000,
    countdownResetPassword: 5 * 60000,
    mfa: {
        codeLength: 6,
    },
    defaultDebounceTime: 300,
    decimalPart: 8,
    integerPart: 10,
    feeAmountInPercent: 1000000,
    percentDecimalPart: 2,
    minAmount: '0.00000001',
    grpcTokenDelimiter: '_',
    tradingPairSeparator: '/',
    fiatDecimal: 2,
    fiatSymbol: 'FIAT',
    delimiter: '.',
};

export const DISPLAY_DATE_FORMAT_CONFIG: IDisplayDateFormat = {
    atmShort: 'MM.dd.y HH:mm',
    atmMedium: 'MM.dd.y HH:mm:ss',
    atmFull: 'MM.dd.y HH:mm:ss z',
    atmShortDate: 'dd.MM.yy',
    atmMediumDate: 'dd.MMMM.y',
    atmFullDate: 'dd.MM.y',
    atmShortTime: 'HH:mm',
    atmMediumTime: 'HH:mm:ss',
    atmFullTime: 'HH:mm:ss z',
    atmMonthYear: 'MM.y',
};

export const DISPLAY_FORMAT_CONFIG: IDisplayFormat = {
    atmFullDate: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZoneName: 'short',
    },
    atmDateTime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    },
    atmShortDate: { year: 'numeric', month: '2-digit', day: '2-digit' },
    atmShortTime: { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false },
    atmHourMinute: { hour: '2-digit', minute: '2-digit', hour12: false },
    atmMonthYear: { month: 'short', year: '2-digit' },
};

export const KEY_CONFIG: IKeyConfig = {
    defaultKeyEncoding: 'hex',
    custodyKeyEncoding: 'base64',
    defaultStringEncoding: 'utf8',
    defaultSignatureEncoding: 'hex',
    mnemonicPhraseLength: 12,
};

export const DEVICE_INFO_NAME = 'deviceInfo';

export const RETRY_STRATEGY_CONFIG: IRetryStrategyConfig = {
    retryWhenTimeout: 5000,
    retryWhenAttempts: 5,
};

export const GTC_EXPIRATION: IGtcExpiration = {
    // GTC = 'One Month'
    expires: 60 * 60 * 24 * 30,
};

export const PAGINATION_CONFIG: IPaginationConfig = {
    pageSize: 20,
    smallPageSize: 5,
};

export const PROPERTY_VALUE_DEFAULTS: IPropertyValue = {
    between: false,
    column: false,
    wrap: false,
    right: false,
    keyMultiline: false,
    valMultiline: true,
};

// config BigNumber object
BigNumber.config({
    // default values are [-7, 20]
    EXPONENTIAL_AT: [-CONFIG.decimalPart, 20],
});

export const FEE_CONFIG: IFeeConfig = {
    defaultFeeSource: FeeSource.FIXED,
};

export const B2C_REGISTER_STEP_URL = '/register/profile';
export const B2C_IDENTIFICATION_STEP_URL = '/register/identification';
export const B2C_ADDITIONAL_INFO_STEP_URL = '/register/additional-info';
export const B2C_DOCUMENT_VERIFICATION = '/register/document-verification';
export const B2C_KEYS_GENERATION_STEP_URL = '/register/keys-generation';

export const B2B_REGISTER_STEP_URL = '/register/b2b/profile';
export const B2B_KEYS_GENERATION_STEP_URL = '/register/b2b/keys-generation';

export const PROFILE_URL = '/profile';

export const HIDE_LOGIN_LINK_ON_PAGES = [
    '/register/confirmation',
    '/register/identification',
    '/register/keys-generation',
    '/login',
    '/login/b2b',
];

export const HIDE_HEADER_CONTENT_ON_PAGES = ['/oauth/rosbank', '/register'];

export const DEBOUNCE_TIME = {
    default: 300,
};

export const QR_SESSION_POLL_INTERVAL = 1000;

export const SUM_SUB: ISumSubConfig = {
    // TODO: flowName should be provided by back-end
    passportFlowName: 'basic-web-kyc',
    // TODO: need rework if will be eng language
    lang: 'ru',
};

export const ATOMYZE_SUPPORT_PHONE: string = '8 (800) 000-00-00';

export const KVAL_LIMIT_CENT = '60000000000000';

import { IUsedeskConfig } from '@share/interfaces/chat.interface';

export const USEDESK: IUsedeskConfig = {
    url: 'https://lib.usedesk.ru/secure.usedesk.ru/widget_160227_33136.js',
};

type userIdentify = (userIdentify: { signature: string }) => void;

declare global {
    interface Window {
        usedeskMessenger: {
            userIdentify: userIdentify;
        };
    }
}

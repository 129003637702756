import { IJwt } from '@share/interfaces/jwt.interface';

export function getInfoFromToken<K extends keyof IJwt>(token: string, property: K): IJwt[K] | null {
    try {
        const splitToken: string = token.split('.')[1];
        const jwt: IJwt = JSON.parse(atob(splitToken));
        return jwt[property];
    } catch (_) {
        return null;
    }
}

import { Injectable, Type } from '@angular/core';

import { NzButtonSize, NzButtonType } from 'ng-zorro-antd/button';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { TranslocoService } from '@ngneat/transloco';

import { DEFAULT_CONFIRM_OPTIONS, DEFAULT_INFO_OPTIONS, DEFAULT_OPEN_MODAL_OPTIONS } from '@core/configs/dialog.config';

import { EModalHeaderColors } from '../../../enums/modal-colors.enum';
import { IConfirmOptions } from '../interfaces/confirm-options.interface';
import { IInfoOptions } from '../interfaces/info-options.interface';
import { IOpenModalOptions } from '../interfaces/open-options.interface';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private _modalService: NzModalService, private _translateService: TranslocoService) {}

    success(options: IInfoOptions): NzModalRef {
        const modalOptions: IConfirmOptions = { ...DEFAULT_INFO_OPTIONS, ...options };
        const modal: NzModalRef = this._modalService.create({
            nzClosable: false,
            nzTitle: `
                <div class="atm-modal-window-header ant-modal-default-title">
                    ${this._translateService.translate(modalOptions.title)}
                </div>
            `,
            nzContent: modalOptions.content,
            nzClassName: `atm-modal-window ${options.headerColor || EModalHeaderColors.GREEN}`,
            nzFooter: [
                {
                    label: this._translateService.translate(options.label || 'buttons.OK'),
                    type: 'primary',
                    size: 'default',
                    onClick: () => {
                        if (options.onSuccess) {
                            options.onSuccess();
                        }
                        modal.destroy();
                    },
                },
            ],
            nzMaskClosable: false,
        });

        return modal;
    }

    confirm(options: IConfirmOptions): NzModalRef {
        const modalOptions: IConfirmOptions = { ...DEFAULT_CONFIRM_OPTIONS, ...options };
        const nzFooter = [
            {
                label: this._translateService.translate(modalOptions.cancelText),
                type: 'default' as NzButtonType,
                size: 'default' as NzButtonSize,
                onClick: () => {
                    if (modalOptions.onCancel) {
                        modalOptions.onCancel();
                    }
                    modal.destroy();
                },
                show: true,
            },
        ];
        if (modalOptions.rejectText) {
            nzFooter.push({
                label: this._translateService.translate(modalOptions.rejectText),
                type: modalOptions.rejectBtnType as NzButtonType,
                size: 'default' as NzButtonSize,
                onClick: () => {
                    modalOptions.onReject();
                    modal.destroy();
                },
                show: true,
            });
        }
        if (modalOptions.okText) {
            nzFooter.push({
                label: this._translateService.translate(modalOptions.okText),
                type: modalOptions.okBtnType as NzButtonType,
                size: 'default' as NzButtonSize,
                onClick: () => {
                    modalOptions.onConfirm();
                    modal.destroy();
                },
                show: true,
            });
        }
        if (modalOptions.redirectText) {
            nzFooter.filter((item, index) => {
                if (index === 0) {
                    item.label = this._translateService.translate(modalOptions.redirectText);
                    item.type = 'primary';
                    item.size = 'default' as NzButtonSize;
                    item.onClick = () => {
                        modalOptions.onConfirm();
                        modal.destroy();
                    };
                    item.show = true;
                    return item;
                } else {
                    item.show = false;
                }
            });
        }
        const modal: NzModalRef = this._modalService.create({
            nzClosable: false,
            nzTitle: this._translateService.translate(modalOptions.title),
            nzContent:
                modalOptions.contentTemplate ||
                this._translateService.translate(modalOptions.content, modalOptions.contentParams),
            nzComponentParams: options.params,
            nzClassName: `atm-modal-window ${modalOptions.headerColor} ${modalOptions.extraCssClass}`,
            nzMaskClosable: modalOptions.closableMask || false,
            nzFooter,
        });
        return modal;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openModal<T = Type<any>>(options: IOpenModalOptions<T>): NzModalRef<T> {
        const modalOptions: IOpenModalOptions = { ...DEFAULT_OPEN_MODAL_OPTIONS, ...options };

        return this._modalService.create({
            nzClosable: false,
            nzTitle: this._translateService.translate(modalOptions.title),
            nzClassName: `atm-modal-window ${modalOptions.headerColor} ${modalOptions.extraCssClass}`,
            nzContent: modalOptions.contentComponent,
            nzComponentParams: modalOptions.componentParams,
            nzFooter: null,
            nzMaskClosable: options.closable,
        });
    }
}

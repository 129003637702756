import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { ATOMYZE_SUPPORT_PHONE } from '@core/configs';

@Component({
    selector: 'atm-user-banned',
    templateUrl: './user-banned.component.html',
    styleUrls: ['./user-banned.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'user-banned',
    },
})
export class UserBannedComponent implements OnInit {
    readonly atomyzeSupportPhone: string = ATOMYZE_SUPPORT_PHONE;

    constructor() {}

    ngOnInit(): void {}
}

import { EModalHeaderColors } from '@share/enums/modal-colors.enum';
import { IConfirmOptions } from '@share/modules/dialog/interfaces/confirm-options.interface';
import { IOpenModalOptions } from '@share/modules/dialog/interfaces/open-options.interface';

export const DEFAULT_INFO_OPTIONS = {
    title: 'modal.SUCCESS_T',
};

export const DEFAULT_CONFIRM_OPTIONS: IConfirmOptions = {
    title: '',
    content: '',
    okBtnType: 'primary',
    rejectBtnType: 'primary',
    okText: 'buttons.CONFIRM',
    cancelText: 'buttons.CANCEL',
    headerColor: EModalHeaderColors.BLUE,
};

export const DEFAULT_OPEN_MODAL_OPTIONS: IOpenModalOptions = {
    title: 'modal.CONFIRM_2FA_T',
    extraCssClass: 'atomyze-modal--blue-color',
    headerColor: EModalHeaderColors.BLUE,
};

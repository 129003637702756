import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { NGXLogger } from 'ngx-logger';

import { IStorageData } from '@share/interfaces/storage-data.interface';

@Injectable({
    providedIn: 'root',
})
export class StorageService implements OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _memorizedData: Map<string, any> = new Map();
    private _ngOnDestroy$: Subject<void> = new Subject();

    constructor(private _logger: NGXLogger) {}

    ngOnDestroy(): void {
        this._ngOnDestroy$.next();
        this._ngOnDestroy$.complete();
    }

    push<T>(key: string, value: T): void {
        const duplicate: boolean = this._memorizedData.get(key) === value;

        if (!duplicate) {
            this._memorizedData.set(key, value);

            try {
                localStorage.setItem(key, JSON.stringify(value));
            } catch (error) {
                this._logger.error(error);
            }
        }
    }

    last<T>(key: string): T {
        return this._getMemorizedData<T>(key)[key];
    }

    remove(key: string): void {
        localStorage.removeItem(key);
        this._memorizedData.delete(key);
    }

    private _getMemorizedData<T>(key: string): IStorageData<T> {
        const memorizedData: IStorageData<T> = { [key]: null };

        if (this._memorizedData.has(key)) {
            memorizedData[key] = this._memorizedData.get(key);
        } else {
            const persistentData: T = this._getDataFromLocalStorage<T>(key);

            if (persistentData !== null && persistentData !== undefined) {
                this._memorizedData.set(key, persistentData);
                memorizedData[key] = persistentData;
            }
        }

        return memorizedData;
    }

    private _getDataFromLocalStorage<T>(key: string): T {
        try {
            const value: string = localStorage.getItem(key);

            return value === null || value === undefined ? null : JSON.parse(value);
        } catch (error) {
            this._logger.error(error);
            return null;
        }
    }
}

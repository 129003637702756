import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgxPermissionsGuard } from 'ngx-permissions';

import { EUserRole } from '@share/enums/role.enum';
import { AuthGuard } from '@share/guards/auth.guard';
import { HasInviteGuard } from '@share/guards/has-invite.guard';
import { KycNotRevokedGuard, KycVerifiedGuard } from '@share/guards/kyc.guard';
import { NotAuthGuard } from '@share/guards/not-auth.guard';
import { TwoFaGuard } from '@share/guards/twoFa.guard';
import { WalletNotExistsGuard } from '@share/guards/wallet.guard';

const routes: Routes = [
    {
        path: 'check',
        loadChildren: () => import('./modules/health-check/health-check.module').then((mod) => mod.HealthCheckModule),
        canActivate: [KycVerifiedGuard, WalletNotExistsGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/auth/login/login.module').then((mod) => mod.LoginModule),
        canActivate: [NotAuthGuard],
    },
    {
        path: 'register',
        loadChildren: () => import('./modules/auth/signup/signup.module').then((mod) => mod.SignupModule),
    },
    {
        path: 'register-sub-account',
        canLoad: [HasInviteGuard],
        loadChildren: () => import('./modules/auth/sub-account/sub-account.module').then((mod) => mod.SubAccountModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./modules/auth/forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule),
    },
    {
        path: 're-identification',
        loadChildren: () =>
            import('./modules/auth/re-identification/re-identification.module').then(
                (mod) => mod.ReIdentificationModule,
            ),
    },
    {
        path: 'change-password',
        loadChildren: () =>
            import('./modules/auth/change-password/change-password.module').then((mod) => mod.ChangePasswordModule),
    },
    {
        path: 'confirm-user',
        loadChildren: () =>
            import('./modules/auth/confirm-user/confirm-user.module').then((mod) => mod.ConfirmUserModule),
        canActivate: [NotAuthGuard],
    },
    {
        path: 'confirm-device',
        loadChildren: () =>
            import('./modules/auth/confirm-device/confirm-device.module').then((m) => m.ConfirmDeviceModule),
        canActivate: [KycVerifiedGuard, WalletNotExistsGuard],
    },
    {
        path: 'explorer',
        data: {
            breadcrumb: 'explorer.T',
        },
        loadChildren: () =>
            import('./modules/blockchain-explorer/blockchain-explorer.module').then(
                (mod) => mod.BlockchainExplorerModule,
            ),
        canActivate: [KycVerifiedGuard, TwoFaGuard, WalletNotExistsGuard],
    },
    {
        path: 'DM',
        loadChildren: () => import('./modules/secret/secret.module').then((mod) => mod.SecretModule),
        canActivate: [KycVerifiedGuard, TwoFaGuard, WalletNotExistsGuard],
    },
    // TODO: should be set an environment variable with stand name to have ability to exclude some pages in prod builds
    {
        path: 'uikit',
        // canLoad: [DevEnvGuard],
        loadChildren: () => import('./modules/uikit/uikit.module').then((mod) => mod.UikitModule),
    },
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'profile',
                data: {
                    breadcrumb: 'profile.T',
                },
                loadChildren: () => import('./modules/profile/profile.module').then((mod) => mod.ProfileModule),
                canActivate: [KycVerifiedGuard, WalletNotExistsGuard],
            },
            {
                path: 'trading',
                // eslint-disable-next-line max-len
                loadChildren: () => import('./modules/trading/trading.module').then((mod) => mod.TradingModule),
                canActivate: [KycVerifiedGuard, KycNotRevokedGuard, TwoFaGuard, WalletNotExistsGuard],
            },
            {
                path: 'streaming',
                loadChildren: () =>
                    import('./modules/trading/modules/streaming/streaming.module').then((mod) => mod.StreamingModule),
                canActivate: [KycVerifiedGuard, KycNotRevokedGuard, TwoFaGuard, WalletNotExistsGuard],
            },
            {
                path: 'market',
                data: {
                    breadcrumb: 'market.T',
                },
                loadChildren: () => import('./modules/market/market.module').then((mod) => mod.MarketModule),
                canActivate: [KycVerifiedGuard, KycNotRevokedGuard, TwoFaGuard, WalletNotExistsGuard],
            },
            {
                path: 'wallets',
                data: {
                    breadcrumb: 'wallets.T',
                    permissions: {
                        only: EUserRole.B2B,
                    },
                },
                loadChildren: () => import('./modules/wallets/wallets.module').then((mod) => mod.WalletsModule),
                canActivate: [
                    KycVerifiedGuard,
                    KycNotRevokedGuard,
                    NgxPermissionsGuard,
                    TwoFaGuard,
                    WalletNotExistsGuard,
                ],
            },
            {
                path: 'wallet',
                data: {
                    breadcrumb: 'wallet-b2c.T',
                    permissions: {
                        only: EUserRole.B2C,
                    },
                },
                loadChildren: () => import('./modules/wallet-b2c/wallet-b2c.module').then((mod) => mod.WalletB2cModule),
                canActivate: [
                    KycVerifiedGuard,
                    KycNotRevokedGuard,
                    NgxPermissionsGuard,
                    TwoFaGuard,
                    WalletNotExistsGuard,
                ],
            },
            {
                path: 'issuances',
                data: {
                    breadcrumb: 'issuances.T',
                    permissions: {
                        only: EUserRole.ISSUER,
                    },
                },
                loadChildren: () => import('./modules/issuances/issuances.module').then((mod) => mod.IssuancesModule),
                canActivate: [
                    KycVerifiedGuard,
                    KycNotRevokedGuard,
                    NgxPermissionsGuard,
                    TwoFaGuard,
                    WalletNotExistsGuard,
                ],
            },
            {
                path: 'orders',
                data: {
                    breadcrumb: 'orders.T',
                },
                loadChildren: () => import('./modules/orders/orders.module').then((mod) => mod.OrdersModule),
                canActivate: [KycVerifiedGuard, KycNotRevokedGuard, TwoFaGuard, WalletNotExistsGuard],
            },
            {
                path: 'validator',
                data: {
                    breadcrumb: 'validator.T',
                    permissions: {
                        only: EUserRole.VALIDATOR,
                    },
                },
                loadChildren: () => import('./modules/validator/validator.module').then((mod) => mod.ValidatorModule),
                canActivate: [
                    KycVerifiedGuard,
                    KycNotRevokedGuard,
                    NgxPermissionsGuard,
                    TwoFaGuard,
                    WalletNotExistsGuard,
                ],
            },
            {
                path: '**',
                redirectTo: 'profile',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import BigNumber from 'bignumber.js';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import { CONFIG } from '@core/configs';

import { ILocalTimezone } from '@share/interfaces/timezone.interface';

export class DateHelpers {
    static createTimestamp(shift?: number): Timestamp {
        const ts: Timestamp = new Timestamp();

        ts.fromDate(new Date());

        if (shift) {
            ts.setSeconds(ts.getSeconds() + shift);
        }

        return ts;
    }

    static isTimestamp(value: unknown): value is Timestamp.AsObject {
        return value && typeof value === 'object' && 'seconds' in value && 'nanos' in value;
    }

    static dateToTimestamp(date: Date): Timestamp {
        if (date instanceof Date) {
            const seconds: number =
                new BigNumber(date.getTime()).dividedBy(1000).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber() || 0;
            const nanos: number =
                new BigNumber(date.getTime()).mod(1000).decimalPlaces(0, BigNumber.ROUND_DOWN).times(1e6).toNumber() ||
                0;

            const timestamp: Timestamp = new Timestamp();

            timestamp.setSeconds(seconds);
            timestamp.setNanos(nanos);

            return timestamp;
        }
    }

    static serverTimeZoneShift(): number {
        return (new Date().getTimezoneOffset() - CONFIG.timezone) * 60 * 1000;
    }

    static dateToStartOfTime(date: Date): Date {
        if (date instanceof Date) {
            const interval: number = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
            const today: Date = new Date();
            const currentTimeZoneOffset: number = today.getTimezoneOffset() * 1000 * 60;

            const startOfDay: number = new BigNumber(date.getTime())
                .minus(currentTimeZoneOffset)
                .dividedBy(interval)
                .decimalPlaces(0, BigNumber.ROUND_DOWN)
                .times(interval)
                .toNumber();

            return new Date(startOfDay + currentTimeZoneOffset);
        }
    }

    static dateToEndOfTime(date: Date): Date {
        if (date instanceof Date) {
            const interval: number = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
            const today: Date = new Date();
            const currentTimeZoneOffset: number = today.getTimezoneOffset() * 1000 * 60;

            const startOfDay: number = new BigNumber(date.getTime())
                .dividedBy(interval)
                .decimalPlaces(0, BigNumber.ROUND_DOWN)
                .times(interval)
                .toNumber();

            return new Date(startOfDay + interval - 1 + currentTimeZoneOffset);
        }
    }

    static timestampToDate(timestampAsObject: Timestamp.AsObject): Date {
        const timestamp: Timestamp = new Timestamp();
        timestamp.setSeconds(timestampAsObject.seconds);
        timestamp.setNanos(timestampAsObject.nanos);

        return timestamp.toDate();
    }

    static timestampObjectToTimestamp(timestampAsObject: Timestamp.AsObject): Timestamp {
        const timestamp: Timestamp = new Timestamp();

        timestamp.setSeconds(timestampAsObject.seconds);
        timestamp.setNanos(timestampAsObject.nanos);

        return timestamp;
    }

    static compareTimestamps(a: Timestamp.AsObject, b: Timestamp.AsObject): number {
        if (a && b) {
            const compareSeconds: number = new BigNumber(a.seconds || 0).comparedTo(b.seconds || 0);

            return compareSeconds === 0 ? new BigNumber(a.nanos || 0).comparedTo(b.nanos || 0) : compareSeconds;
        }
    }

    static offsetToGMT(offset: number): string {
        const sign: string = offset < 0 ? '+' : '-';
        const positiveOffset: number = Math.abs(offset);

        return `${sign}${DateHelpers.addZeroGMT(positiveOffset / 60)}${DateHelpers.addZeroGMT(positiveOffset % 60)}`;
    }

    static localTimezone(): ILocalTimezone {
        const offset: BigNumber = new BigNumber(new Date().getTimezoneOffset());
        const abs: BigNumber = offset.abs();
        const sign: string = offset.isLessThan(0) ? '+' : '-';
        const hours: string = ('00' + abs.dividedBy(60).decimalPlaces(0, BigNumber.ROUND_DOWN).toString()).slice(-2);
        const minutes: string = ('00' + abs.mod(60).toString()).slice(-2);

        return {
            name: Intl.DateTimeFormat().resolvedOptions().timeZone,
            offset: `${sign}${hours}:${minutes}`,
        };
    }

    private static addZeroGMT(value: number): string {
        return `${value < 10 ? '0' : ''}${value}`;
    }
}

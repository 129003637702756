import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '@core/services/notifications.service';
import { Observable, Subject } from 'rxjs';
import { map, pluck, takeUntil } from 'rxjs/operators';
import { DateHelpers } from 'src/app/core/date';
import { Notification } from '@proto/account/notifications_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

@Component({
    selector: 'atm-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'notifications grid-full gap-row-20',
    },
})
export class NotificationsComponent implements OnInit, OnDestroy {
    oneDayInSeconds = 86400;
    twoDaysInSeconds = this.oneDayInSeconds * 2;

    notifications$: Observable<Notification.AsObject[]> = this._notifications.state$.pipe(pluck('messages'));
    notificationsVisible = false;

    _destroy$ = new Subject();

    today$: Observable<Notification.AsObject[]> = this.notifications$.pipe(
        map((notifications) =>
            notifications.filter(
                (notification) => this.secondsDifference(notification.createdat) < this.oneDayInSeconds,
            ),
        ),
    );
    yesterday$: Observable<Notification.AsObject[]> = this.notifications$.pipe(
        map((notifications) =>
            notifications.filter(
                (notification) =>
                    this.secondsDifference(notification.createdat) >= this.oneDayInSeconds &&
                    this.secondsDifference(notification.createdat) < this.twoDaysInSeconds,
            ),
        ),
    );
    later$: Observable<Notification.AsObject[]> = this.notifications$.pipe(
        map((notifications) =>
            notifications.filter(
                (notification) => this.secondsDifference(notification.createdat) >= this.twoDaysInSeconds,
            ),
        ),
    );

    constructor(private _notifications: NotificationsService) {}

    secondsDifference(timestamp: Timestamp.AsObject): number {
        const todaySeconds = DateHelpers.dateToTimestamp(new Date()).getSeconds();
        return todaySeconds - timestamp?.seconds;
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    markAsRead(id: string): void {
        this._notifications.markAsRead(id).pipe(takeUntil(this._destroy$)).subscribe();
    }

    toogleNotifications(): void {
        this.notificationsVisible = !this.notificationsVisible;
    }
}

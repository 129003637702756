<span class="notifications__title">{{ 'notification.T' | transloco }}</span>

<div class="notifications__buttons">
    <!--    TODO: add integration-->
    <button nz-button nzSize="small" nzType="default" [disabled]="true">
        {{ 'buttons.CLEAR_ALL' | transloco }}
    </button>
</div>

<ng-container *ngIf="today$ | async as todayNotifications">
    <ng-container *ngIf="todayNotifications.length">
        <span class="notifications__moment">{{ 'dates.TODAY' | transloco }}</span>
        <ng-container *ngFor="let item of todayNotifications; index as i">
            <ng-container *ngTemplateOutlet="notificationCard; context: { $implicit: item, index: i }"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="yesterday$ | async as yesterdayNotifications">
    <ng-container *ngIf="yesterdayNotifications.length">
        <span class="notifications__moment">{{ 'dates.YESTERDAY' | transloco }}</span>
        <ng-container *ngFor="let item of yesterdayNotifications; index as i">
            <ng-container *ngTemplateOutlet="notificationCard; context: { $implicit: item, index: i }"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="later$ | async as laterNotifications">
    <ng-container *ngIf="laterNotifications.length">
        <span class="notifications__moment">{{ 'dates.LATER' | transloco }}</span>
        <ng-container *ngFor="let item of laterNotifications; index as i">
            <ng-container *ngTemplateOutlet="notificationCard; context: { $implicit: item, index: i }"></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #notificationCard let-notification>
    <div class="notificationCard">
        <div class="notificationCard__body">
            <span>{{ notification?.text }}</span>

            <div class="notificationCard__buttons">
                <i nz-icon nzType="close" (click)="markAsRead(notification?.notificationid)" class="close-icon"></i>
            </div>
        </div>

        <span class="notificationCard__created">{{ notification.createdat | timeDistance }}</span>
    </div>
</ng-template>

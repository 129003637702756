import { Injectable } from '@angular/core';
import { NotificationGrpcService } from '@grpc/services/account/notification.grpc.service';
import { ENotificationType } from '@proto/account/notification-type.enum_pb';
import {
    MarkAsReadResponse,
    Notification,
    NotificationsList,
    SendNotificationResponse,
} from '@proto/account/notifications_pb';
import { Store } from '@share/helpers/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { handleGrpcError } from '@share/helpers/handle-grpc-error';
import { UserService } from '@share/services/user.service';

export interface INotificationsStore {
    messages: Notification.AsObject[];
}

const INITIAL_NOTIFICATIONS_STORE: INotificationsStore = {
    messages: [],
};

@Injectable({
    providedIn: 'root',
})
export class NotificationsService extends Store<INotificationsStore> {
    streamSubject = new Subject();

    constructor(private _notificationGrpcService: NotificationGrpcService, private _userService: UserService) {
        super(INITIAL_NOTIFICATIONS_STORE);
    }

    openStream(): Observable<Notification.AsObject> {
        return this._notificationGrpcService
            .openChannel()
            .pipe(this._handleNotification.bind(this), takeUntil(this.streamSubject));
    }

    closeStream(): void {
        this.streamSubject.next();
        this.streamSubject.complete();
    }

    sendNotification(text: string, title: string): Observable<SendNotificationResponse.AsObject> {
        return this._notificationGrpcService.sendNotification(title, text);
    }

    loadNotifications(): Observable<unknown> {
        return this._notificationGrpcService.getNotificationsList().pipe(
            tap((resp: NotificationsList.AsObject) => {
                const messages = resp.notificationsList.filter(
                    (item) => item.type === ENotificationType.UNDEFINED && !item.read,
                );
                this.setState({ ...this.state, messages });
            }),
            takeUntil(this.streamSubject),
        );
    }

    markAsRead(id: string): Observable<MarkAsReadResponse.AsObject> {
        const messages = this.state.messages.filter((i) => i.notificationid !== id);
        return this._notificationGrpcService.markAsRead(id).pipe(
            filter((resp) => resp.success),
            tap(() => this.setState({ ...this.state, messages })),
        );
    }

    private _handleNotification(source: Observable<Notification.AsObject>) {
        return source.pipe(
            tap((item) => {
                if (item.type === ENotificationType.UNDEFINED && !item.read) {
                    this.state.messages.push(item);
                }
                if (item.type === ENotificationType.SUMSUB_UPDATED) {
                    this._refreshUser();
                }
            }),
        );
    }

    private _refreshUser(): void {
        this._userService.refreshUser().pipe(handleGrpcError()).subscribe();
    }
}

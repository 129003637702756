import { Injectable } from '@angular/core';

import { NzNotificationService } from 'ng-zorro-antd/notification';

import { TranslocoService } from '@ngneat/transloco';
import { NzNotificationRef } from 'ng-zorro-antd/notification/typings';
import { ErrorCode } from '@proto/errors/errors.enum_pb';

export enum ENotificationMessageTypes {
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success',
    INFO = 'info',
}

export enum ENotificationClass {
    INFO = 'box-notification--gray',
    ERROR = 'box-notification--red',
    SUCCESS = 'box-notification--green',
    WARNING = 'box-notification--orange',
}

export interface INotificationOptions {
    type: ENotificationMessageTypes;
    content?: string;
    translatedText?: string;
}

function transformClass(notificationType: ENotificationMessageTypes): string {
    if (notificationType === null || notificationType === undefined) {
        return '';
    }
    switch (notificationType) {
        case ENotificationMessageTypes.SUCCESS:
            return ENotificationClass.SUCCESS;
        case ENotificationMessageTypes.ERROR:
            return ENotificationClass.ERROR;
        case ENotificationMessageTypes.INFO:
            return ENotificationClass.INFO;
        case ENotificationMessageTypes.WARNING:
            return ENotificationClass.WARNING;
    }
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private notification: NzNotificationService, private translateService: TranslocoService) {}

    create(options: INotificationOptions): NzNotificationRef {
        return this.notification.create(
            options.type,
            this.transformTitle(options.type),
            this.generateContent(options),
            {
                nzDuration: 4500,
                nzPlacement: 'bottomRight',
                nzClass: transformClass(options.type),
            },
        );
    }

    createErrorNotification(code: ErrorCode): NzNotificationRef {
        let content: string;

        // TODO Add other error codes here
        switch (code) {
            case ErrorCode.INSUFFICIENT_FUNDS:
                content = 'errors.INSUFFICIENT_FUNDS';
                break;
            default:
                content = 'handle-grpc-error.ERROR.SERVER_NOT_AVAILABLE';
        }

        return this.create({
            type: ENotificationMessageTypes.ERROR,
            content,
        });
    }

    private generateContent(options: INotificationOptions): string {
        if (options.content) {
            return this.translateService.translate(options.content);
        }
        return options.translatedText || '';
    }

    private transformTitle(notificationType: ENotificationMessageTypes): string {
        if (notificationType === null || notificationType === undefined) {
            return '';
        }
        switch (notificationType) {
            case ENotificationMessageTypes.SUCCESS:
                return this.translateService.translate('notification.SUCCESS');
            case ENotificationMessageTypes.ERROR:
                return this.translateService.translate('notification.ERROR');
            case ENotificationMessageTypes.INFO:
                return this.translateService.translate('notification.INFO');
            case ENotificationMessageTypes.WARNING:
                return this.translateService.translate('notification.WARNING');
        }
    }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { TranslocoService } from '@ngneat/transloco';

import { CONFIG, STORAGE_NAMES, USEDESK } from '@core/configs';
import { AuthService } from '@share/services/auth.service';
import { UserService } from '@share/services/user.service';
import { UseDeskService } from '@core/services/use-desk.service';
import { EUserRole } from '@share/enums/role.enum';
import { DialogService } from '@share/modules/dialog/services/dialog.service';
import { StorageService } from '@share/services/storage.service';
import { EModalHeaderColors } from '@share/enums/modal-colors.enum';
import { NotificationsService } from '@core/services/notifications.service';
import { UserProfile } from '@proto/account/user_pb';

@Component({
    selector: 'atm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    profile: UserProfile.AsObject;
    private _checkAcceptedAgreements$: BehaviorSubject<boolean> = new BehaviorSubject(this.hasAcception());
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _title: Title,
        private _translateService: TranslocoService,
        private _userService: UserService,
        private _useDeskService: UseDeskService,
        private _notificationsService: NotificationsService,
        private _storageService: StorageService,
        private _dialogService: DialogService,
    ) {}

    ngOnInit(): void {
        this._refreshUser();
        this._watchAndSetTitle();
        this._watchAgreements();
        this._initPostScripts();
        this._loadNotifications();
    }

    private _loadNotifications(): void {
        this._authService
            .isLoggedIn()
            .pipe(
                switchMap((isloggedIn) => {
                    if (isloggedIn) {
                        return forkJoin([
                            this._notificationsService.loadNotifications(),
                            this._notificationsService.openStream(),
                        ]);
                    }
                    this._notificationsService.closeStream();
                    return of();
                }),
            )
            .subscribe();
    }

    private _refreshUser(): void {
        this._authService
            .isLoggedIn()
            .pipe(
                take(1),
                switchMap((isLoggedIn) => (isLoggedIn ? this._userService.getRefreshUser().pipe(take(1)) : of(null))),
            )
            .subscribe();
    }

    private _watchAndSetTitle(): void {
        this._router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                map(() => {
                    let child: ActivatedRoute = this._route.firstChild;

                    while (child.firstChild) {
                        child = child.firstChild;
                    }

                    return child.snapshot.data.title;
                }),
            )
            .subscribe((data: string) => {
                const title: string = data
                    ? `${this._translateService.translate(data)} | ${CONFIG.title}`
                    : CONFIG.title;

                this._title.setTitle(title);
            });
    }

    private _watchAgreements(): void {
        this._checkAcceptedAgreements$
            .pipe(
                switchMap((isAccepted) => {
                    if (!isAccepted) {
                        this.showAgreementsConfirmModal();
                        return of(null);
                    } else {
                        return this._initPostScripts();
                    }
                }),
            )
            .subscribe();
    }

    private _initPostScripts(): Observable<string> {
        return this._authService.isLoggedIn().pipe(switchMap((isLoggedIn) => this._postAction(isLoggedIn)));
    }

    private _postAction(isLoggedIn: boolean): Observable<string> {
        if (isLoggedIn) {
            return this._loadUseDesk();
        }
        this._useDeskService.loadScript(USEDESK.url);
        this._useDeskService.checkUser('');
        return of(null);
    }

    private _loadUseDesk(): Observable<string> {
        return this._userService.getUserProfile().pipe(
            map((user) => {
                if (user && this._userService.hasRole(EUserRole.B2C)) {
                    return user.phonenumber;
                } else if (user && !this._userService.hasRole(EUserRole.B2C)) {
                    return user.email;
                } else {
                    return '';
                }
            }),
            distinctUntilChanged(),
            tap(() => {
                this._useDeskService.loadScript(USEDESK.url);
                this._useDeskService.checkUser('');
            }),
        );
    }

    private hasAcception(): boolean {
        return !!this._storageService.last(STORAGE_NAMES.storageAcceptedAgreementsTime);
    }

    private showAgreementsConfirmModal(): void {
        this._dialogService.success({
            title: 'modal.AGREEMENTS_T',
            label: 'buttons.AGREE',
            headerColor: EModalHeaderColors.BLUE,
            content: `
            <p class="modal-content">
                <span class="modal-content__item">${this._translateService.translate('modal.AGREEMENTS_CONTENT')}</span>
                <span class="modal-content__item">${this._translateService.translate('modal.DATA_PROCESSING')}</span>
                <span class="modal-content__item">${this._translateService.translate('modal.REFUSE_CASE')}</span>
            </p>
            `,
            onSuccess: () => {
                const now: number = Date.now();
                this._storageService.push(STORAGE_NAMES.storageAcceptedAgreementsTime, now);
                this._checkAcceptedAgreements$.next(true);
            },
        });
    }
}

import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';

import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceInfo } from 'ngx-device-detector/device-detector.service';

import { EDevicePlatforms } from '@share/enums/device-platforms.enum';
import { IDeviceInfo } from '@share/interfaces/config.interface';

@Injectable({
    providedIn: 'root',
})
export class DeviceInfoService {
    constructor(private deviceDetector: DeviceDetectorService) {}

    getDeviceInfo(): Observable<IDeviceInfo> {
        const deviceInfo: DeviceInfo = this.deviceDetector.getDeviceInfo();

        return of({
            platform: this.getPlatform(),
            operationSystem: deviceInfo.os,
            browser: deviceInfo.browser,
        });
    }

    private getPlatform(): EDevicePlatforms {
        let platform: EDevicePlatforms;

        if (this.deviceDetector.isMobile()) {
            platform = EDevicePlatforms.MOBILE;
        } else if (this.deviceDetector.isTablet()) {
            platform = EDevicePlatforms.TABLET;
        } else {
            platform = EDevicePlatforms.DESKTOP;
        }

        return platform;
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
    fromEvent,
    merge,
    pipe,
    throwError,
    timer,
    BehaviorSubject,
    MonoTypeOperatorFunction,
    Observable,
    Subject,
} from 'rxjs';
import { catchError, distinctUntilChanged, mapTo, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';

import BigNumber from 'bignumber.js';

import { environment } from '@environments/environment';

import { IServiceError } from '@grpc/interfaces/common/service-error.interface';
import { RegistrationGrpcService } from '@grpc/services/account/registration.grpc.service';
import { AuthGrpcService } from '@grpc/services/auth/auth.grpc.service';

import { ReadResponse } from '@proto/account/user_pb';
import { ResponseWithTokenAndPublicKey } from '@proto/auth/auth_pb';
import { Empty } from '@proto/common/web-empty_pb';

import { EUserRole } from '@share/enums/role.enum';
import { getInfoFromToken } from '@share/helpers/get-info-from-token';
import { IJwt } from '@share/interfaces/jwt.interface';
import { KeysService } from '@share/services/keys.service';
import { StorageService } from '@share/services/storage.service';
import { UserService } from '@share/services/user.service';

import { UserDialogsService } from '@share/services/user-dialogs.service';
import { handleAuthErrors } from '../helpers/handle-auth-errors';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private _isLoggedIn$: BehaviorSubject<boolean>;
    private _cancelRenew$: Subject<void> = new Subject();
    private _destroyed$: Subject<void> = new Subject();
    private _invalidJWTListener$: Observable<Event> = fromEvent(document, 'InvalidJWT');

    constructor(
        private _authGrpcService: AuthGrpcService,
        private _keysService: KeysService,
        private _storageService: StorageService,
        private _logger: NGXLogger,
        private _registrationGrpcService: RegistrationGrpcService,
        private _router: Router,
        private _userService: UserService,
        private userDialogService: UserDialogsService,
    ) {
        this.userDialogService.setConfirmCallBack(() => this.logout());
        this._renewTokenWithTTL(true);
        merge(this._invalidJWTListener$, this._userService.refreshUserFailed$)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this.logout();
            });

        this._isLoggedIn$ = new BehaviorSubject(!!this._storageService.last(environment.token));
    }

    ngOnDestroy(): void {
        this._cancelRenew$.next();
        this._destroyed$.next();
    }

    isLoggedIn(): Observable<boolean> {
        return this._isLoggedIn$.asObservable().pipe(distinctUntilChanged());
    }

    authorizeB2B(
        email: string,
        password: string,
        code: string = null,
    ): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        return this._authGrpcService.authorizeB2B(email, password, code).pipe(
            tap((data: ResponseWithTokenAndPublicKey.AsObject) => {
                this._loggedIn(data.token);
                this._renewTokenWithTTL();
            }),
            this._awaitUserRefresh(),
        );
    }

    authorizeB2C(
        phone: string,
        password: string,
        code: string = null,
    ): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        return this._authGrpcService.authorizeB2C(phone, password, code).pipe(
            tap((data: ResponseWithTokenAndPublicKey.AsObject) => {
                this._loggedIn(data.token);
                this._renewTokenWithTTL();
            }),
            this._awaitUserRefresh(),
        );
    }

    authorizeAfterPasswordReset(token: string, password: string): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        return this._authGrpcService.changePassword(token, password).pipe(
            tap((data: ResponseWithTokenAndPublicKey.AsObject) => {
                this._loggedIn(data.token);
                this._renewTokenWithTTL();
            }),
            this._awaitUserRefresh(),
        );
    }

    authorizeByToken(token: string): Observable<ReadResponse.AsObject> {
        this._loggedIn(token);
        this._renewTokenWithTTL();
        return this._userService.getRefreshUser();
    }

    registrationB2C(password: string): Observable<Empty.AsObject> {
        return this._registrationGrpcService.registrationB2C(password).pipe(handleAuthErrors);
    }

    loginByToken(token: string): void {
        this._loggedIn(token);
    }

    logout(redirect: boolean = true): void {
        this._storageService.remove(environment.token);
        this._isLoggedIn$.next(false);
        this._cancelRenew$.next();

        if (redirect === true) {
            this._router.navigateByUrl(this._userService.hasRole(EUserRole.B2C) ? '/login' : '/login/b2b');
        }
    }

    renewTokenImmediately(): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        return this._renewToken();
    }

    getToken(): string {
        return this._storageService.last(environment.token);
    }

    isEtcUser(): boolean {
        const token: string | null = this.getToken();
        if (!token) {
            return false;
        }
        return getInfoFromToken(token, 'isETC') === true;
    }

    private _awaitUserRefresh(): MonoTypeOperatorFunction<ResponseWithTokenAndPublicKey.AsObject> {
        return pipe(switchMap((data) => this._userService.getRefreshUser().pipe(mapTo(data))));
    }

    private _loggedIn(token: string): void {
        this._storageService.push(environment.token, token);
        this._saveOtfPublicKey(token);
        this._isLoggedIn$.next(true);
    }

    private _saveOtfPublicKey(token: string): void {
        if (token) {
            try {
                const splitToken: string = token.split('.')[1];
                const jwt: IJwt = JSON.parse(atob(splitToken));

                if (jwt?.otfPublicKey) {
                    this._keysService.updateKeyInfo(jwt.otfPublicKey, null);
                }
            } catch (err) {
                this._logger.debug(err);
            }
        }
    }

    private _renewTokenWithTTL(isFirst?: boolean): void {
        const token: string = this._storageService.last(environment.token);
        const ttl: number = isFirst ? 0 : this._tokenTTL(token);

        if (token) {
            // TODO: Probably it needs to rework, cause it can be resource-intensive
            timer(ttl)
                .pipe(switchMapTo(this._renewToken(token)), takeUntil(this._cancelRenew$))
                .subscribe(
                    (data: ResponseWithTokenAndPublicKey.AsObject) => {
                        this._loggedIn(data.token);
                        this._renewTokenWithTTL();
                    },
                    (err: IServiceError) => {
                        this._logger.warn(err);
                        this.logout();
                    },
                );
        }
    }

    private _tokenTTL(token: string): number {
        let period = 0;

        if (token) {
            try {
                const splitToken: string = token.split('.')[1];
                const jwt: IJwt = JSON.parse(atob(splitToken));

                period = new BigNumber(Date.now())
                    .dividedBy(1000)
                    .decimalPlaces(0, BigNumber.ROUND_DOWN)
                    .negated()
                    .plus(jwt.exp)
                    .minus(environment.authDiff)
                    .decimalPlaces(0)
                    .times(1000)
                    .toNumber();
            } catch (err) {
                this._logger.debug(err);
            }
        }

        return period;
    }

    private _renewToken(token: string = null): Observable<ResponseWithTokenAndPublicKey.AsObject> {
        return this._authGrpcService.renewAuthorization(token).pipe(
            catchError((err: IServiceError) => {
                this._logger.error(err);
                this.logout(false);

                return throwError(err);
            }),
        );
    }
}

<div class="header__logo">
    <img [routerLink]="'/'" class="header__logo-img" src="../../../../assets/images/logo.svg" alt="Logo" />
</div>

<nav class="header__menu">
    <ng-container *ngIf="isHeaderEnabled$ | async">
        <!-- TODO Временно закоментировано в рамках задачи по NFT -->
        <!--<a
            [class.header__link&#45;&#45;disabled]="
                profile?.restrictions?.deactivationstatus === deactivationstatusTypes.PENDING
            "
            class="header__link"
            routerLink="trading"
            [routerLinkActive]="routerLinkActiveCls"
        >
            {{ 'header.MENU.TRADING' | transloco }}
        </a>-->
        <a
                [class.header__link&#45;&#45;disabled]="
                profile?.restrictions?.deactivationstatus === deactivationstatusTypes.PENDING
            "
                class="header__link"
                routerLink="market"
                [routerLinkActive]="routerLinkActiveCls"
        >
            {{ 'header.MENU.MARKET' | transloco }}
        </a>
        <a
                *ngxPermissionsOnly="[roles.B2B]"
                class="header__link"
                routerLink="wallets"
                routerLinkActive="header__link--selected"
        >
            {{ 'header.MENU.WALLETS' | transloco }}
        </a>
        <a
                *ngxPermissionsOnly="[roles.B2C]"
                [class.header__link--disabled]="
                profile?.restrictions?.deactivationstatus === deactivationstatusTypes.PENDING
            "
                class="header__link"
                routerLink="wallet"
                [routerLinkActive]="routerLinkActiveCls"
        >
            {{ 'header.MENU.WALLET' | transloco }}
        </a>
        <!--        TODO: block in development are temporarily hidden-->
        <!--        <a class="header__link" routerLink="orders" routerLinkActive="header__link&#45;&#45;selected">-->
        <!--            {{ 'header.MENU.ORDERS' | transloco }}-->
        <!--        </a>-->
        <a
                *ngxPermissionsOnly="[roles.ISSUER]"
                class="header__link"
                routerLink="issuances"
                routerLinkActive="header__link--selected"
        >
            {{ 'header.MENU.ISSUANCES' | transloco }}
        </a>

        <!--        TODO: block in development are temporarily hidden-->
        <!--        <a-->
        <!--            *ngxPermissionsOnly="[roles.VALIDATOR]"-->
        <!--            class="header__link"-->
        <!--            routerLink="validator"-->
        <!--            routerLinkActive="header__link&#45;&#45;selected"-->
        <!--        >-->
        <!--            {{ 'header.MENU.VALIDATOR' | transloco }}-->
        <!--        </a>-->

        <a
                [class.header__link--disabled]="
                profile?.restrictions?.deactivationstatus === deactivationstatusTypes.PENDING
            "
                class="header__link"
                routerLink="explorer"
                [routerLinkActive]="routerLinkActiveCls"
        >
            {{ 'header.MENU.EXPLORER' | transloco }}
        </a>
    </ng-container>
</nav>

<atm-breadcrumbs class="breadcrumbs header__breadcrumbs"></atm-breadcrumbs>

<div class="header__tools">
    <ng-container [ngTemplateOutlet]="(isHeaderEnabled$ | async) ? authorizedTpl : nonAuthorizedTpl"></ng-container>
</div>

<ng-template #authorizedTpl>
    <div *ngIf="profile" #headerPanel class="header-panel">
        <a
                class="header__tools-link"
                [routerLink]="navigateFromProfile()"
                routerLinkActive="header__tools-link--selected"
        >
            <img class="header__tools-img" src="/assets/icons/profile.svg" />
        </a>

        <a
                class="header__tools-link"
                (click)="toogleNotifications()"
                [class.header__tools-link--selected]="notificationsVisible"
        >
            <nz-badge
                    [nzCount]="notifications?.length"
                    class="notifications__badge"
                    [nzStyle]="{ backgroundColor: '#091A3B' }"
                    *ngIf="notificationsCount$ | async as notifications"
            >
                <img class="header__tools-img" src="/assets/icons/bell.svg" />
            </nz-badge>
        </a>
    </div>
</ng-template>

<ng-template #nonAuthorizedTpl>
    <a class="header__tools-login" *ngIf="isLoginLinkEnabled$ | async" [routerLink]="['login']" replaceUrl>{{
        'header.LOGIN' | transloco
        }}</a>
</ng-template>

<ng-container *ngIf="notificationsVisible">
    <atm-notifications
            [style.right]="notificationsRightMargin"
            [style.top]="notificationsTopMargin"
            class="notifications__list"
    ></atm-notifications>
</ng-container>

import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Metadata } from 'grpc-web';

import { GrpcDevTool } from '@grpc/helpers/grpc-dev-tool';

import { ParameterToChange } from '@proto/account/user.enum_pb';
import { UserServicePromiseClient } from '@proto/account/user_grpc_web_pb';
import {
    ChangeParameterRequest,
    CheckVerificationCodeRequest,
    ConfirmParameterChangeRequest,
    ConfirmVerificationPhoneRequest,
    ReadResponse,
    SendVerificationCodeRequest,
    UserProfile,
    VerifyNotConfirmedPhoneRequest,
} from '@proto/account/user_pb';
import { Empty } from '@proto/common/web-empty_pb';

import { GrpcConfigService } from '../../services/grpc-config.service';
import { IGrpcConfigInterface } from '../../interfaces/grpc-config.interface';
import { grpcUnary } from '../../helpers/grpc-unary';
import { grpcMetadata } from '../../helpers/grpc-metadata';

@Injectable({
    providedIn: 'root',
})
export class UserGrpcService {
    @GrpcDevTool
    private _client: UserServicePromiseClient = new UserServicePromiseClient(this._config.url);

    constructor(@Inject(GrpcConfigService) private _config: IGrpcConfigInterface) {}

    getUserData(): Observable<ReadResponse.AsObject> {
        const req: Empty = new Empty();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<ReadResponse.AsObject>(() => this._client.read(req, meta));
    }

    getUserProfileData(): Observable<UserProfile.AsObject> {
        const req: Empty = new Empty();
        const meta: Metadata = grpcMetadata();

        return grpcUnary<UserProfile.AsObject>(() => this._client.getProfile(req, meta));
    }

    sendChangeParameterVerificationCode(method: ParameterToChange): Observable<Empty.AsObject> {
        const req: SendVerificationCodeRequest = new SendVerificationCodeRequest();
        const meta: Metadata = grpcMetadata();

        req.setParametertochange(method);

        return grpcUnary<Empty.AsObject>(() => this._client.sendChangeParameterVerificationCode(req, meta));
    }

    checkChangeParameterVerificationCode(parameter: ParameterToChange, code: string): Observable<Empty.AsObject> {
        const req: CheckVerificationCodeRequest = new CheckVerificationCodeRequest();
        const meta: Metadata = grpcMetadata();

        req.setParametertochange(parameter);
        req.setVerificationcode(code);

        return grpcUnary<Empty.AsObject>(() => this._client.checkChangeParameterVerificationCode(req, meta));
    }

    changeParameter(
        parameter: ParameterToChange,
        oldParameter: string,
        newParameter: string,
    ): Observable<Empty.AsObject> {
        const req: ChangeParameterRequest = new ChangeParameterRequest();
        const meta: Metadata = grpcMetadata();

        req.setParametertochange(parameter);
        req.setOldparameter(oldParameter);
        req.setNewparameter(newParameter);

        return grpcUnary<Empty.AsObject>(() => this._client.changeParameter(req, meta));
    }

    confirmParameterChange(
        parameter: ParameterToChange,
        oldCodeParameter: string,
        newCodeParameter: string,
    ): Observable<Empty.AsObject> {
        const req: ConfirmParameterChangeRequest = new ConfirmParameterChangeRequest();
        const meta: Metadata = grpcMetadata();

        req.setParametertochange(parameter);
        req.setCodefromoldparameter(oldCodeParameter);
        req.setCodefromnewparameter(newCodeParameter);

        return grpcUnary<Empty.AsObject>(() => this._client.confirmParameterChange(req, meta));
    }

    verifyNotConfirmedPhone(phone: string): Observable<Empty.AsObject> {
        const req: VerifyNotConfirmedPhoneRequest = new VerifyNotConfirmedPhoneRequest();
        const meta: Metadata = grpcMetadata();

        req.setPhone(phone);

        return grpcUnary<Empty.AsObject>(() => this._client.verifyNotConfirmedPhone(req, meta));
    }

    confirmVerificationPhone(code: string): Observable<Empty.AsObject> {
        const req: ConfirmVerificationPhoneRequest = new ConfirmVerificationPhoneRequest();
        const meta: Metadata = grpcMetadata();

        req.setCode(code);

        return grpcUnary<Empty.AsObject>(() => this._client.confirmVerificationPhone(req, meta));
    }
}

import { NgModule } from '@angular/core';

// main module and essential components - charts must be included in lazy-loaded modules where they're being used
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import * as echarts from 'echarts/lib/echarts';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    imports: [NgxEchartsModule.forRoot({ echarts })],
    exports: [NgxEchartsModule],
})
export class EchartsModule {}

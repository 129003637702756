import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HasInviteGuard implements CanLoad {
    canLoad(route: Route, segments: UrlSegment[]): boolean {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.has('invite');
    }
}

import { Injectable } from '@angular/core';

import { forkJoin, of, throwError, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';

import { environment } from '@environments/environment';

import { LoggerMonitor } from '@core/logger';

import { IServiceError } from '@grpc/interfaces/common/service-error.interface';

import { IDeviceInfo } from '@share/interfaces/config.interface';
import { IFingerprintComponent } from '@share/interfaces/dev-fingerprint.interface';
import { DeviceInfoService } from '@share/services/device-info.service';
import { FingerPrintService } from '@share/services/fingerprint.service';
import { StorageService } from '@share/services/storage.service';
import { ConfigGrpcService } from '@grpc/services/config/config.grpc.service';
import { Documents, GetAppConfigResponse, GetWebConfigResponse } from '@proto/admin/config_pb';
import { ConfigStoreService } from './config-store.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(
        private _logger: NGXLogger,
        private _fingerPrintService: FingerPrintService,
        private _storageService: StorageService,
        private _deviceInfoService: DeviceInfoService,
        private configGrpcService: ConfigGrpcService,
        private configStoreService: ConfigStoreService,
    ) {}

    initConfigurations(): Promise<void> {
        this._logger.registerMonitor(new LoggerMonitor());
        return new Promise((resolve, reject) => {
            forkJoin([this._getFingerprint(), this._getDeviceInfo(), this._initWebConfig(), this._initAppConfig()])
                .pipe(
                    catchError((er) => {
                        reject();
                        return throwError(er);
                    }),
                )
                .subscribe(() => {
                    resolve();
                });
        });
    }

    private _getFingerprint(): Observable<string> {
        return this._fingerPrintService.readDevFingerprint().pipe(
            filter((data: IFingerprintComponent[]) => !!data && data.length >= 2),
            map((fingerprint: IFingerprintComponent[]) => {
                const device: string = fingerprint[0].value || null;
                this._storageService.push(environment.fingerprint, device);

                return device;
            }),
            catchError((err: IServiceError) => {
                this._logger.error(err);

                return of(null);
            }),
        );
    }

    private _getDeviceInfo(): Observable<IDeviceInfo> {
        return this._deviceInfoService.getDeviceInfo().pipe(
            map((deviceInfo: IDeviceInfo) => {
                this._storageService.push(environment.deviceInfo, deviceInfo);
                return deviceInfo;
            }),
            catchError((err: IServiceError) => {
                this._logger.error(err);

                return of(null);
            }),
        );
    }

    private _initWebConfig(): Observable<GetWebConfigResponse.AsObject> {
        return this.configGrpcService.getWebConfig().pipe(
            tap((config: GetWebConfigResponse.AsObject) => this.configStoreService.setState({ config })),
            catchError((err: IServiceError) => {
                this._logger.error(err);

                return of(null);
            }),
        );
    }

    private _initAppConfig(): Observable<GetAppConfigResponse.AsObject> {
        return this.configGrpcService.getAppConfig().pipe(
            map((config: GetAppConfigResponse.AsObject) => config.documents),
            tap((documents: Documents.AsObject) => {
                return this.configStoreService.setState({ documents });
            }),
            catchError((err: IServiceError) => {
                this._logger.error(err);

                return of(null);
            }),
        );
    }
}
